import { Container, Grid } from "@mui/material";
import React from "react";

export default function AdvisorAgreement() {
    return (
        <Container className="app-page">
            <Grid container className="app-page-content" rowSpacing={5}>
                <Grid className="app-page-title" item xs={12}>
                    <h1>Advisor's Agreement</h1>
                </Grid>
                <Grid item xs={12}>
                    <p>The personal information willingly provided by me to the Equitable Life Insurance Company of Canada ("Equitable Life"), collected on this Agreement and held in its files, will be used by Equitable Life for the purposes of verification of eligibility to receive commissions, commission processing, and for servicing and administrating related to the Policy, this Agreement and any supplementary documents. The information on file is accessible for the above purposes only to authorized employees of, and third parties retained by</p>

                    <p>The personal information willingly provided by me to The Equitable Life Insurance Company of Canada ("Equitable Lite"), collected on this Agreement and held in its files, will be used by Equitable Lite for the purposes of verification of eligibility to receive commissions, commission processing, and for servicing and administration related to the Policy, this Agreement and any supplementary documents. The information on file is accessible for the above purposes only to authorized employees of, and third parties retained by Equitable Life, and any other person whom I authorize.</p>

                    <p>I agree to (a) comply with all licensing, legislative and regulatory requirements, professional codes of ethics, industry and Canadian Life and Health Insurance Association guidelines and requirements applicable where I carry on business; (b) comply with the rules, regulations and business requirements of Equitable Life now in force and as amended or added to from time to time and (c) ensure that any personal information of all policyholders, insured employees and their dependents collected and used by me and disclosed by me for the purposes of servicing the Policy and policyholder, insured employees and their dependents is adequately safeguarded by me in accordance with privacy laws applicable where I carry on business.</p>

                    <p>I acknowledge and agree that, upon termination of the above Policy, any HealthConnector Services or any other action, event or change that results in premium payments for the above Policy any HealthConnector Services, ceasing at any time, then all commission and compensation prepaid beyond the date payment of Policy, Services or Cl policy premiums ceased, regardless if shared among other Advisors, shall be a debt owed by me to Equitable Life and repaid by me to Equitable Life immediately upon demand. As well, Equitable Life may, in its sole discretion, deduct and offset such prepaid and unearned commission and compensation from any other commission and compensation owed by Equitable Life to me. Prepaid commission and compensation shall be prorated over the 12 month Policy year.</p>
                        
                    <p>I have reviewed the contents of the Application for Group Insurance Form 495 and all accompanying applicable forms and hereby declare that the information contained therein represents the wishes of the Policyholder. I also agree with the terms of this Agreement.</p>
                        
                    <p>I hereby consent, authorize and direct any company, organization, association, regulator, or government body to release to Equitable Life information contained in its files concerning: my agency; my employment; my business records; my education record; my credit record; information conceming certificates, licenses and registrations; any information concerning complaints or disciplinary measures; and/ or any other information relevant to Equitable regarding its ongoing review of my performance or my agency's performance. I further consent and authorize Equitable Life to obtain a criminal activity clearance report from any police agency or government. (collectively the "Consent and Authorization'").</p>

                    <p>I further agree that, upon request by Equitable Life, I will require any agents, employees or representatives of the undersigned to provide to Equitable Life a consent and authorization in form similar to the Consent and Authorization.</p>

                    <p>The undersigned acknowledge that any T4A shall be in their license/contract name regardless of the bank account holders name.</p>
                </Grid>
            </Grid>
        </Container>
    );
};