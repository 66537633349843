import { Container, Grid } from "@mui/material";

export default function TermsAgreement() {
    return (
        <Container className="app-page">
            <Grid container className="app-page-content" rowSpacing={5}>
                <Grid className="app-page-title" item xs={12}>
                    <h1>Plan Sponsor's Acknowledgement and Agreement</h1>    
                    <p>Requested effective date: Apr 01, 2023</p>
                    <span>The policyholder or authorized representative of the policyholder (the “Plan Sponsor”) hereby acknowledges and agrees to the following rights and obligations, each of which commences on the effective date of the Policy (the “Effective Date’)</span>
                </Grid>
                <Grid item xs={12}>
                    <h3>Electronic Delivery of Policy and Employee Booklets</h3>
                    <ol type="a">
                        <li>Where the Plan Sponsor requests electronic delivery of the Policy issued by Equitable Life, the Plan Sponsor authorizes Equitable Life to deliver the Policy to the Plan Sponsor in pdf format by public Internet email to the email address provided by the Plan Sponsor.</li>
                        <li>Where the Plan Sponsor requests electronic delivery of Employee booklets, the Plan Sponsor agrees to access and to have Plan Members access the Employee Booklets from the Plan Administrator or Plan Member areas of the EquitableHealth.ca site.</li>
                        <li>The Plan Sponsor acknowledges that information sent by public Internet email is non-secure as it is not encrypted and that Equitable Life is not responsible for any misuse or interception of such information.</li>
                    </ol>
                    
                    <h3>Plan Member Area of EquitableHealth.ca</h3>
                    <ol type="a">
                        <li>Plan Members who register online will be able to access their personal information through the Plan Member area of EquitableHealth.ca.</li>
                    </ol>
                    
                    <h3>Advisor Web Services</h3>
                    <ol type="a">
                        <li>The Plan Sponsor authorizes Equitable Life to permit the Plan Sponsor’s Advisor on the Policy and the Advisor’s representatives to register through Advisor Web Services for view-only access to Plan Member information as required for the Advisor’s ongoing management of the Policy on behalf of the Plan Sponsor.</li>
                    </ol>

                    <h3>Advisor Disclosure</h3>
                    <ol type="a">
                        <li>The Plan Sponsor is aware that the person providing advice on the purchase of this Policy receives a commission or fee for service and may also receive additional compensation in the form of bonuses or incentives.</li>
                    </ol>
                    
                    <h3>Policy Contract</h3>
                    <ol type="a">
                        <li>The Plan Sponsor declares that the statements, answers, and information in this Application are full, complete and true, and the Plan Sponsor agrees that:
                            <ol type="i">
                                <li>This Application shall form part of the Policy contract;</li>
                                <li>The Policy does not take effect unless this signed Application, the first month’s premium payment and employee enrolment forms are received at Head Office prior to the requested effective date and unless this Application is approved by Equitable Life.</li>
                            </ol>
                        </li>
                    </ol>

                    <h3>Premium Payments</h3>
                    <ol type="a">
                        <li>The payment submitted with this Application is to be applied to the first month’s premium payment. This is refundable only if the Application is not approved by Equitable LifNegotiation of the first month’s premium cheque does not in itself constitute approval of the Application.</li>
                        <li>Premium payments and all monies which the Plan Sponsor forwards to Equitable Life shall not have been paid to Equitable Life until received by Equitable Life at its Head Office</li>
                    </ol>

                    <h3>Administration Obligations</h3>
                    <p>The Policy will be administered by Equitable Life’s Head Office, but the Plan Sponsor shall be solely and exclusively responsible for performing the following administration services (the “Services”):</p>
                    <ol type="a">
                        <li>Administer the Policy for all eligible employees (the “Plan Member(s)”) via a secure portion of Equitable Life’s website located at www.equitablehealth.ca (the “Site”) in accordance with the terms of the Policy and Equitable Life’s administrative and underwriting rules and procedures;</li>
                        <li>Accurately and promptly enter all enrollment data and updated Plan Member information on the Site; Use Equitable Life’s standard administration forms and documents, and have Plan Members sign and complete the appropriate Equitable Life forms;</li>
                        <li>Maintain all documentation and forms for Plan Members, including but not limited to application and beneficiary forms;</li>
                        <li>Maintain accurate records of insurance coverage for each Plan Member;</li>
                        <li>Upon request, provide Equitable Life with all Plan Member data and coverage information;</li>
                        <li>Enroll Plan Members on the Site and explain to Plan Members their insurance coverage and the procedures for accessing information on the portions of the Site to which Plan Members have access;</li>
                        <li>Update Plan Members’ coverage and other information on the Site as necessary, and explain to Plan Members any changes in coverage;</li>
                        <li>Immediately update the Site when a Plan Member has resigned, been terminated, or is no longer eligible for insurance coverage or any benefit under the Policy;</li>
                        <li>Provide relevant information to Equitable Life to enable Equitable Life to process Plan Members’ claims;</li>
                        <li>Receive billing documents in electronic format via Plan Administrator Web Services; and l. Provide the personnel and equipment necessary to perform the Services</li>
                    </ol>
            
                    <h3>Plan Administrator(s)</h3>
                    <ol type="a">
                        <li>The Plan Sponsor shall designate the person(s) responsible for performing the Services and/or the person(s) able to view Plan Member information on the Site (the “Plan Administrator”) on behalf of the Plan Sponsor.</li>
                        <li>​The Plan Sponsor is responsible for the Plan Administrator’s and/or TPA’s actions and omissions in performing the Services.</li>
                        <li>​The Plan Administrator(s) will be able to view plan details for classes to which certificates under their division belonIf, for example, a Plan Administrator has access to Divisions 1 and 2 and the certificates within those divisions belong to Classes A and B, then the Plan Administrator will have access to view plan details for Classes A and If the intent is to restrict access to plan details for classes within each Division, then further instructions must be provided to the Plan Sponsor’s Equitable Life Customer Relationship Specialist.</li>
                        <li>​If the Plan Sponsor has appointed itself as Plan Administrator, it may terminate its appointment and shall provide Equitable Life with not less than 30 days’ prior written notice of such termination. Equitable Life may terminate the Plan Sponsor’s appointment as Plan Administrator upon not less than 60 days’ prior written notice of such termination.</li>
                        <li>​If the Plan Sponsor appointed a TPA, it may terminate its appointment and shall provide Equitable Life with not less than 30 days’ prior written notice of such termination. Equitable Life may terminate the appointment of the TPA and shall provide the Plan Sponsor with not less than 60 days’ prior written notice of such termination and shall provide the TPA with not less than 45 days’ prior written notice of such termination.</li>
                    </ol>

                    <h3>Recordkeeping</h3>
                    <ol type="a">
                        <li>The Plan Sponsor shall maintain all records and documents used in performing the Services.</li>
                        <li>The Plan Sponsor shall make all such records and documents accessible to Equitable Life for:
                            <ol type="i">
                                <li>Examination and audit while the Policy is in effect;</li>
                                <li>Examination and audit following termination of the Policy for the purpose of claims administration and adjudication; and</li>
                                <li>​A period of 7 years following termination of the Policy.</li>
                            </ol>
                        </li>
                        <li>The Plan Sponsor shall maintain its books and records regarding the Services in accordance with generally accepted accounting principles.</li>
                        <li>​The obligations in this provision apply to the Plan Sponsor regardless of if the Policy is administered by Equitable Life’s Head Office, by the Plan Sponsor itself, or by a Plan Administrator appointed by the Plan Sponsor (including any TPA).</li>
                        <li>​The obligations in this provision shall survive termination of the Policy.</li>
                    </ol>

                    <h3>Claims</h3>
                    <ol type="a">
                        <li>The Plan Sponsor shall not make any settlement of any claim under the Policy.</li>
                    </ol>

                    <h3>Indemnity and Release</h3>
                    <ol type="a">
                        <li>​The Plan Sponsor shall indemnify and hold Equitable Life harmless from any claims, damages and losses that Equitable Life may incur has a result of the acts, omissions or negligence of the Plan Sponsor, its appointed TPA, or as a result of the Plan Sponsor or TPA’s failure to perform the Services or otherwise follow the provisions of the Policy, Equitable Life guidelines and procedures, the Group Administration Guide, or this Acknowledgement and Agreement</li>
                        <li>​This provision shall survive termination of the Policy. Independence The Plan Sponsor and its appointed Plan Administrator(s) (including any appointed TPA) are acting on their own behalf in performing the Services. Equitable Life and the Plan Sponsor/Plan Administrator(s) are independent contractors. This provision shall survive termination of the Policy. Assignment The Plan Sponsor may not assign or outsource its obligations with respect to the Policy or the Services without Equitable Life’s prior written consent.</li>
                    </ol>

                    <h3>Independence</h3>
                    <ol type="a">
                        <li>​The Plan Sponsor and its appointed Plan Administrator(s) (including any appointed TPA) are acting on their own behalf in performing the Services. Equitable Life and the Plan Sponsor/Plan Administrator(s) are independent contractors.</li>
                        <li>​This provision shall survive termination of the Policy. Assignment The Plan Sponsor may not assign or outsource its obligations with respect to the Policy or the Services without Equitable Life’s prior written consent.</li>
                    </ol> 
                    
                    <h3>Termination of Rights and Obligations</h3>
                    <ol type="a">
                        <li>​The Plan Sponsor’s rights and obligations with respect to the Services will automatically terminate on the date the Policy terminates.</li>
                        <li>​Equitable Life may immediately terminate the Plan Sponsor’s rights with respect to performing or continuing to perform the Services if a) the Plan Sponsor becomes insolvent or bankrupt; b) the Plan Sponsor fails to comply with any of the material provisions of this Acknowledgement and Agreement; or c) for any reason on 31 days’ written notice to the Plan Sponsor.</li>
                    </ol>

                    <h3>Governing Law</h3>
                    <ol type="a">
                        <li>The laws of the province of residence of the Plan Sponsor shall apply to the terms herein.</li>
                    </ol>

                    <h3>Healthcare Spending Account</h3>
                    <ol type="a">
                        <li>The amount payable to Equitable Life of Canada will be charged on your next monthly premium statement.  The amount owing is the responsibility of the Plan Sponsor.  A premium statement will be provided on a monthly basis to the Plan Sponsor.</li>
                    </ol>
                </Grid>
            </Grid>
        </Container>
    )
}