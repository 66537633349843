import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

type FormMaskedInputProps = {
    name: string;
    mask: string;
    value?: string;
    onChange?: any;
    label?: string;
    style?: any;
    errors?: any;
};

export default function FormMaskedInput({ name, mask, value, onChange, label, style, errors } : FormMaskedInputProps) {
    return (
        <div className="form-input" style={style}>
            {!!label && <Typography className="form-input-label" sx={{ fontStyle: 'italic' }}>{label}</Typography>}
            <InputMask mask={mask} value={value} onChange={onChange}>
                { 
                    (inputProps) => 
                        <TextField 
                            name={name} 
                            variant='standard' 
                            error={!!errors[name]}
                            helperText={errors[name]} 
                            {...inputProps} 
                        />
                }
            </InputMask>
        </div>
    );
}