
export const maxSizeInBytes = 20 * 1024 * 1024; // 20 MB

export const allowedMimeTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/msword', // Microsoft Word (legacy .doc)
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word (.docx)
    'application/vnd.ms-excel', // Microsoft Excel (legacy .xls)
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Microsoft Excel (.xlsx)
    'text/csv',
];

export const yesNoOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];

export const currentBenefitsCoveredOptions = [
    { value: 'Basic Life', label: 'Basic Life' },
    { value: 'Accidental Death & Dismemberment', label: 'Accidental Death & Dismemberment' },
    { value: 'Optional Life', label: 'Optional Life' },
    { value: 'Dependent Life', label: 'Dependent Life' },
    { value: 'Long Term Disability', label: 'Long Term Disability' },
    { value: 'Health', label: 'Health' },
    { value: 'Dental', label: 'Dental' },
    { value: 'Health Care Spending Account', label: 'Health Care Spending Account' },
];

export const planSponsorRemitAgencies = [
    { value: 'EI', label: 'EI' },
    { value: 'CPP/QPP', label: 'CPP/QPP' },
    { value: 'WCB/WSIB/CSST', label: 'WCB/WSIB/CSST' },
];

export const provinceOptions = [
    { value: 'AB', label: 'Alberta' },
    { value: 'BC', label: 'British Columbia' },
    { value: 'MB', label: 'Manitoba' },
    { value: 'NB', label: 'New Brunswick' },
    { value: 'NL', label: 'Newfoundland and Labrador' },
    { value: 'NS', label: 'Nova Scotia' },
    { value: 'ON', label: 'Ontario' },
    { value: 'PE', label: 'Prince Edward Island' },
    { value: 'QC', label: 'Quebec' },
    { value: 'SK', label: 'Saskatchewan' },
    { value: 'NT', label: 'Northwest Territories' },
    { value: 'NU', label: 'Nunavut' },
    { value: 'YT', label: 'Yukon' },
];

export const urlParamFields = [
    'sponsor',
    'effective',
    'classa',
    'classaltd',
    'classalives',
];

export const urlParamClassicFields = [
    'sponsor',
    'effective',
    'classa',
    'classaltd',
    'commissions',
];
