import { Container, Grid } from "@mui/material";


export default function GatePage() {
    return (
        <Container className="app-page">
            <Grid container className="app-page-content" rowSpacing={5}>
                <Grid className="app-page-title" item xs={12}>
                    <h1>Application for Group Insurance</h1>
                    <br/>
                    <span>Thank you for your interest in EZBenefits. Before you can complete our online application, you must request a proposal from the EZBenefits team. To request a proposal, please send us an email to <a className="consent-link" href="mailto:QUOTEEZbenefits@equitable.ca" target="_blank" rel="noreferrer">QUOTEEZbenefits@equitable.ca</a> with the following:</span>
                </Grid>
                <Grid item xs={12}>
                    <h3>1. Plan Sponsor information including census and current or past benefits</h3>
                </Grid>
                <Grid item xs={12}>
                    <h3>2. Advisor Information including commissions</h3>
                </Grid>
                <Grid item xs={12}>
                    <h3>3. Requested Plan Design</h3>
                </Grid>
            </Grid>
        </Container>
    );
};