import { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import MainForm from "./MainForm";
import FrontPage from "./FrontPage";
import GatePage from './GatePage';
import { getClassLTDValue, getClassLivesValue, getClassValue, getCommissionValue, getEffectiveDateValue, isValidUrlParamFields } from "../helpers";
import { UrlParams } from "../types";
import { urlParamClassicFields, urlParamFields } from "../constants";

export default function HomePage() {
    const [formStep, setFormStep] = useState<boolean>(false);
    const [searchParams] = useSearchParams();

    const values = searchParams.get('values');
    let urlParams: UrlParams = {
        urlId: '',
        sponsor: '',
        effective: '',
        classa: '',
        classaltd: '',
        classalives: '',
        classb: '',
        classbltd: '',
        classblives: '',
        commissions: '',
        commissionsLife: '',
        commissionsDepLife: '',
        commissionsADD: '',
        commissionsLTD: '',
        commissionsEHC: '',
        commissionsDental: '',
    };
    let isValidFields = false;
    
    if (!!values) {
        const values = searchParams.get('values');
        const sponsor = searchParams.get('sponsor');

        const urlId = values ? values.slice(0, 14) : '';
        const effectiveDate = values ? values.slice(14, 21) : '';
        const classA = values ? values.slice(24, 26) : '';
        const classALTD = values ? values.slice(34, 36) : '';
        const classALives = values ? values.slice(44, 46) : '';

        const classB = values ? values.slice(54, 56) : '';
        const classBLTD = values ? values.slice(64, 66) : '';
        const classBLives = values ? values.slice(74, 76) : '';

        const commissionsLife = values ? values.slice(84, 88) : '';
        const commissionsDepLife = values ? values.slice(94, 98) : '';
        const commissionsADD = values ? values.slice(104, 108) : '';
        const commissionsLTD = values ? values.slice(114, 118) : '';
        const commissionsEHC = values ? values.slice(124, 128) : '';
        const commissionsDental = values ? values.slice(134, 138) : '';

        urlParams = {
            sponsor,
            urlId,
            effective: getEffectiveDateValue(effectiveDate),
            classa: getClassValue(classA),
            classaltd: getClassLTDValue(classALTD),
            classalives: getClassLivesValue(classALives),
            classb: getClassValue(classB),
            classbltd: getClassLTDValue(classBLTD),
            classblives: getClassLivesValue(classBLives),
            commissionsLife: getCommissionValue(commissionsLife),
            commissionsDepLife: getCommissionValue(commissionsDepLife),
            commissionsADD: getCommissionValue(commissionsADD),
            commissionsLTD: getCommissionValue(commissionsLTD),
            commissionsEHC: getCommissionValue(commissionsEHC),
            commissionsDental: getCommissionValue(commissionsDental),
        };

        isValidFields = isValidUrlParamFields(urlParams, urlParamFields);
    } else {
        urlParams = {
            urlId: 'N/A',
            sponsor: searchParams.get('sponsor'),
            effective: searchParams.get('effective'),
            classa: searchParams.get('ClassA'),
            classaltd: searchParams.get('ClassALTD'),
            classalives: searchParams.get('ClassALives'),
            classb: searchParams.get('ClassB'),
            classbltd: searchParams.get('ClassBLTD'),
            classblives: searchParams.get('ClassBLives'),
            commissions: searchParams.get('Commissions'),
        };

        isValidFields = isValidUrlParamFields(urlParams, urlParamClassicFields);
    }

    if (!isValidFields) {
        return <GatePage />
    }

    return (
        <div className="home-page">
            {
                !formStep ? <FrontPage onSubmit={setFormStep} /> : <MainForm urlParams={urlParams} />
            }
        </div>
    );
};