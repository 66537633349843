import { allowedMimeTypes, maxSizeInBytes } from "../constants";

export const formatSentenceCase = (string) => {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const isValidFileType = (file) => {
    const isValidMimeType = allowedMimeTypes.includes(file.type ?? '');  
    return isValidMimeType;
}

export const isValidFileSize = (file) => {
    const isValidFileSize = file.size <= maxSizeInBytes;
    return isValidFileSize;
}

export const isValidUrlParamFields = (urlParams, requiredFields) => {
    const checkKeys = Object.keys(urlParams)
    for (let key of requiredFields) {
        if (!checkKeys.includes(key) || !urlParams[key]) {
            return false;
        }
    }
    return true;
}

export const getClassValue = (classValue) => {
    const value =  parseInt(classValue, 16);

    if (value === 66) { // ASCII 66 = B = Bronze
        return 'Bronze';
    } else if (value === 83) { // ASCII 83 = S = Silver
        return 'Silver';
    } else if (value === 71) { // ASCII 71 = G = Gold
        return 'Gold';
    } else if (value === 80) { // ASCII 80 = P = Platinum
        return 'Platinum';
    } else {
        return '';
    }
}

export const getClassLivesValue = (lives) => {
    const value =  parseInt(lives, 16);
    if (value.toString() === 'NF' || lives === '0' || !lives) {
        return '';
    } else {
        return value.toString();
    }
}

export const getClassLTDValue = (classValue) => {
    const value =  parseInt(classValue, 16);
    
    if (value === 78) { // ASCII 78 = N = No
        return 'No';
    } else if (value === 89) { // ASCII 89 = Y = Yes
        return 'Yes';
    } else {
        return '';
    }
}

export const getCommissionValue = (commissionValue) => {
    if (commissionValue.slice(0, 2) === 'KX') { // KX = Standard
        return 'Standard';
    } else if (commissionValue.slice(0, 2) === 'LP') {  // LP = Special
        return 'Special';
    }
    
    const value = parseInt(commissionValue, 16);

    return value / 10000; // Convert to decimal
}

export const getEffectiveDateValue = (effectiveDateValue) => {
    const value = parseInt(effectiveDateValue, 16);

    const dateString = value.toString();
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    const date = `${year}-${month}-${day}`;

    return date;
}

export const getCustomCommissionValue = (commissionValue) => {
    if (commissionValue === 'Standard') {
        return 'Standard';
    } else if (commissionValue === 'Special') {
        return 'Custom';
    } else {
        return (Number(commissionValue) * 100).toFixed(2).toString();
    }
}
