import { Button, Container, Grid } from "@mui/material";

type FrontPageProps = {
    onSubmit: any;
}


export default function FrontPage({ onSubmit }: FrontPageProps) {
    return (
        <Container className="app-page">
            <Grid container className="app-page-content" rowSpacing={5}>
                <Grid className="app-page-title" item xs={12}>
                    <h1>Application for Group Insurance</h1>
                    <br/>
                    <span>We are delighted that you have chosen EZBenefits to support the health and productivity of your client’s employees. You’re one step closer to completing the application process.</span>
                </Grid>
                <Grid item xs={12}>
                    <span>Before you begin, gather the following information / documents:</span>
                </Grid>
                <Grid item xs={12}>
                     <Grid container rowSpacing={5}>
                        <Grid item xs={8}>
                            <h3>1. Completed Employee Census</h3>
                            <span>Please download the census template provided on this portal for format and instructions</span>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: 10 }}>
                            <Button variant="contained">
                                <a style={{ color: 'white', textDecoration: 'none' }} href="/files/EquitableLife-Census-v102.xlsx" target="_blank" download>
                                    Download Template
                                </a>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={5}>
                        <Grid item xs={8}>
                            <h3>2. Completed pre-authorize debit form</h3>
                            <span>Can be sent separately in an email at a later date; however, the implementation of the group will not start until the form has been received</span>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: 10 }}>
                            <Button variant="contained">
                                <a style={{ color: 'white', textDecoration: 'none' }} href="/files/EquitableLife-PAD-v101.pdf" target="_blank" download>
                                    Download Form
                                </a>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <h3>3. Void cheque or banking information</h3>
                    <span>Advisor’s bank details for direct deposit of commissions</span>
                </Grid>
                <Grid item xs={12}>
                    <h3>4. Copy of your current license</h3>
                    <span>Not required if already on file</span>
                </Grid>
                <Grid item xs={12}>
                    <h3>5. Copy of your current E&O Insurance</h3>
                    <span>Not required if already on file</span>
                </Grid>
                <Grid item xs={12}>
                    <p>Ready to start the application:</p>
                    <Button variant="contained" onClick={_ => {
                        onSubmit(true);
                        window.scrollTo(0, 0);
                    }} >Start Application</Button>
                </Grid>
            </Grid>
        </Container>
    );
};