import { Container, Grid } from "@mui/material";
import React from "react";

export default function Header () {
    return (
        <header className="header">
            <Container>
                <Grid container rowSpacing={5}>
                    <Grid item xs={12}>
                        <img src={require('../assets/Equitable-Logo-TM.png')} alt="Equitable Life of Canada" className="header-logo" />
                    </Grid>
                </Grid>
            </Container>
        </header>
    );
};

