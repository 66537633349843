import { FormControl, FormHelperText, MenuItem, Select, Typography } from "@mui/material";
import { EZFormData, SelectOption } from "../types";

type FormSelectProps = {
    name: string;
    label?: string;
    options: SelectOption[];
    value?: string;
    style?: any;
    errors?: EZFormData;
    disabled?: boolean;
    onChange?: any;
};

export default function FormSelect({ name, label, options, value, onChange, style, disabled = false, errors }: FormSelectProps) {
    return (
        <FormControl error={!!errors[name]} sx={{ width: '100%', marginBottom: '1rem', ...style }}>
            {!!label && <Typography className="form-input-label" sx={{ fontStyle: 'italic' }}>{label}</Typography>}
            <Select
                variant="standard"
                name={name}
                onChange={onChange}
                value={value ?? ''}
                inputProps={{MenuProps: {disableScrollLock: true}}}
                disabled={disabled}
            >
                {
                    options.map(option => (
                        <MenuItem key={option.value} value={option.value}>{option.label}&nbsp;</MenuItem>
                    ))
                }
            </Select>
            {!!errors[name] && <FormHelperText style={{ marginLeft: 0 }}>{errors[name]}</FormHelperText>}
        </FormControl>
    )
}