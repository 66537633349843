import { Button, Checkbox, Container, FormControlLabel, Grid } from '@mui/material';
import { useState } from 'react';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import axios from 'axios';
import React from 'react';
import { EZFormData, UrlParams } from '../types';
import { currentBenefitsCoveredOptions, planSponsorRemitAgencies, provinceOptions, yesNoOptions } from '../constants';
import { formatSentenceCase, getCustomCommissionValue, isValidFileSize, isValidFileType } from '../helpers';
import FormMaskedInput from './FormMaskedInput';

type MainFormProps = {
    urlParams?: UrlParams
};

export default function MainForm({ urlParams }: MainFormProps) {
    const [errors, setErrors] = useState<EZFormData>({});
    const [files, setFiles] = useState({employeeCensus: [], preAuthorizedDebit: [], license:[], voidCheque:[], eAndOInsurance:[]});
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);

    const commission = urlParams.commissions ?? 'Custom';
    let commissionValue = '';

    if (commission === 'Standard') {
        commissionValue = 'Equitable Life Standard Commission Scale G&L';
    } else if (commission === 'Special') {
        commissionValue = 'Custom Commission Scale';
    } else if ((!isNaN(Number(commission)) && Number(commission) > 0)) {
        commissionValue = 'Flat Commission';
    }

    const hasCustomCommission = !urlParams.commissions;

    const defaultParams = {
        proposalId: urlParams.urlId,
        companyName: urlParams.sponsor,
        effectiveDate: urlParams.effective,
        benefitClassASelection: urlParams.classa,
        benefitClassALtdIncluded: urlParams.classaltd,
        benefitClassALives: urlParams.classalives,
        benefitClassBSelection: urlParams?.classb,
        benefitClassBLtdIncluded: urlParams?.classbltd,
        benefitClassBLives: urlParams?.classblives,
        commissionInformation:  commissionValue,
        commissionOptionOptionalLifePercent: (hasCustomCommission || commissionValue === 'Flat Commission') ? '10.00' : '',
        commissionOptionLifePercent: hasCustomCommission ? getCustomCommissionValue(urlParams.commissionsLife) : commissionValue === 'Flat Commission' ? (Number(urlParams.commissions) * 100).toFixed(2).toString() : '',
        commissionOptionDepLifePercent: hasCustomCommission ? getCustomCommissionValue(urlParams.commissionsDepLife) : commissionValue === 'Flat Commission' ? (Number(urlParams.commissions) * 100).toFixed(2).toString() : '',
        commissionOptionADDPercent: hasCustomCommission ? getCustomCommissionValue(urlParams.commissionsADD) : commissionValue === 'Flat Commission' ? (Number(urlParams.commissions) * 100).toFixed(2).toString() : '',
        commissionOptionLTDPercent: hasCustomCommission ? getCustomCommissionValue(urlParams.commissionsLTD) : commissionValue === 'Flat Commission' ? (Number(urlParams.commissions) * 100).toFixed(2).toString() : '',
        commissionOptionEHCPercent: hasCustomCommission ? getCustomCommissionValue(urlParams.commissionsEHC) : commissionValue === 'Flat Commission' ? (Number(urlParams.commissions) * 100).toFixed(2).toString() : '',
        commissionOptionDentalPercent: hasCustomCommission ? getCustomCommissionValue(urlParams.commissionsDental) : commissionValue === 'Flat Commission' ? (Number(urlParams.commissions) * 100).toFixed(2).toString() : '',
        planSponsoredAgency: [],
        subsidiaries: [],
    };

    const [formData, setFormData] = useState<EZFormData>(defaultParams);
    const [showClassB] = useState<boolean>(!!defaultParams.benefitClassBSelection);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitButtonDisabled(true);

        const newErrors = {};
        const fieldValidations = [
            { name: 'companyName', label: 'Plan Sponsor Name', value: formData.companyName, rules: { required: true } },
            { name: 'legalStatus', label: 'Plan Sponsor Legal Status', value: formData.legalStatus, rules: { required: true } },
            { name: 'legalStatusOther', label: 'Plan Sponsor Legal Status', value: formData.legalStatus === 'Other' ? formData.legalStatusOther ?? '' : '', rules: { required: formData.legalStatus === 'Other' ? true : false } },
            { name: 'streetAddress', label: 'Plan Sponsor Street Address', value: formData.streetAddress, rules: { required: true } },
            { name: 'poBox', label: 'PO Box', value: formData.poBox, rules: { required: false } },
            { name: 'city', label: 'Plan Sponsor City', value: formData.city, rules: { required: true } },
            { name: 'province', label: 'Plan Sponsor Province', value: formData.province, rules: { required: true } },
            { name: 'postalCode', label: 'Plan Sponsor Postal Code', value: formData.postalCode, rules: { required: true } },
            { name: 'planSponsorName', label: 'Plan Sponsor Name', value: formData.planSponsorName, rules: { required: true } },
            { name: 'planSponsorPhone', label: 'Plan Sponsor Phone', value: formData.planSponsorPhone, rules: { required: true } },
            { name: 'planAdministratorName', label: 'Plan Administrator Name', value: formData.planAdministratorName, rules: { required: true } },
            { name: 'planAdministratorPhone', label: 'Plan Administrator Phone', value: formData.planAdministratorPhone, rules: { required: true } },
            { name: 'companyEmail', label: 'Plan Administrator Email', value: formData.companyEmail, rules: { required: true, pattern: /\S+@\S+\.\S+/ } },
            { name: 'mailingAddress', label: 'Mailing Address', value: formData.mailingAddress, rules: { required: false } },
            { name: 'subsidiaryCount', label: 'Subsidiary Count', value: formData.subsidiaryCount, rules: { required: true } },
            { name: 'effectiveDate', label: 'Effective Date', value: formData.effectiveDate, rules: { required: false } },
            { name: 'employerContributionLife', label: 'Employer Contribution Life', value: formData.employerContributionLife, rules: { required: true } },
            { name: 'employerContributionADD', label: 'Employer Contribution ADD', value: formData.employerContributionADD, rules: { required: true } },
            { name: 'employerContributionDependentLife', label: 'Employer Contribution Dependent Life', value: formData.employerContributionDependentLife, rules: { required: true } },
            { name: 'employerContributionHealth', label: 'Employer Contribution Health', value: formData.employerContributionHealth, rules: { required: true } },
            { name: 'employerContributionDental', label: 'Employer Contribution Dental', value: formData.employerContributionDental, rules: { required: true } },
            { name: 'employerContributionLifeClassB', label: 'Employer Contribution Life', value: formData.employerContributionLifeClassB, rules: { required: false } },
            { name: 'employerContributionADDClassB', label: 'Employer Contribution ADD', value: formData.employerContributionADDClassB, rules: { required: false } },
            { name: 'employerContributionDependentLifeClassB', label: 'Employer Contribution Dependent Life', value: formData.employerContributionDependentLifeClassB, rules: { required: false } },
            { name: 'employerContributionHealthClassB', label: 'Employer Contribution Health', value: formData.employerContributionHealthClassB, rules: { required: false } },
            { name: 'employerContributionDentalClassB', label: 'Employer Contribution Dental', value: formData.employerContributionDentalClassB, rules: { required: false } },
            { name: 'coverageInforced', label: 'Coverage Inforced', value: formData.coverageInforced, rules: { required: true } },
            { name: 'currentInsurerPolicy', label: 'Current Insurer Policy', value: formData.currentInsurerPolicy, rules: { required: formData.coverageInforced === 'Yes' ? true : false } },
            { name: 'currentInsurerEffectiveDate', label: 'Current Insurer Effective Date', value: formData.currentInsurerEffectiveDate, rules: { required: formData.coverageInforced === 'Yes' ? true : false } },
            { name: 'currentInsurerTerminationDate', label: 'Current Insurer Termination Date', value: formData.currentInsurerTerminationDate, rules: { required: formData.coverageInforced === 'Yes' ? true : false } },
            { name: 'currentBenefitsCovered', label: 'Current Benefits Covered', value: formData.currentBenefitsCovered, rules: { required: false } },
            { name: 'workersCompensation', label: 'Workers Compensation', value: formData.workersCompensation, rules: { required: true } },
            { name: 'payrollEligibility', label: 'Payroll Eligibility', value: formData.payrollEligibility, rules: { required: true } },
            { name: 'planSponsoredAgency', label: 'Plan Sponsored Agency EI', value: formData.planSponsoredAgency, rules: { required: false } },
            { name: 'planSponsoredAgencyOther', label: 'Plan Sponsored Agency Other', value: formData.planSponsoredAgencyOther, rules: { required: false } },
            { name: 'nonWorkingMemberCount', label: 'Non Working Member Count', value: formData.nonWorkingMemberCount, rules: { required: true } },
            { name: 'divisionA', label: 'Division A', value: formData.divisionA, rules: { required: true } },
            { name: 'divisionANameShown', label: 'Division A Name Shown', value: formData.divisionANameShown, rules: { required: true } },
            { name: 'divisionB', label: 'Division B', value: formData.divisionB, rules: { required: false } },
            { name: 'divisionBNameShown', label: 'Division B Name Shown', value: formData.divisionBNameShown, rules: { required: false } },
            { name: 'benefitClassASelection', label: 'Benefit Class A Selection', value: formData.benefitClassASelection, rules: { required: true } },
            { name: 'benefitClassALtdIncluded', label: 'Benefit Class A LTD Included', value: formData.benefitClassALtdIncluded, rules: { required: true } },
            { name: 'benefitClassBSelection', label: 'Benefit Class B Selection', value: formData.benefitClassBSelection, rules: { required: false } },
            { name: 'benefitClassBLtdIncluded', label: 'Benefit Class B LTD Included', value: formData.benefitClassBLtdIncluded, rules: { required: false } },
            { name: 'spendingAccountIssued', label: 'Spending Account Issued', value: formData.spendingAccountIssued, rules: { required: true } },
            { name: 'spendingAccountType', label: 'Spending Account Type', value: formData.spendingAccountType, rules: { required: formData.spendingAccountIssued === 'Yes' ? true : false } },
            { name: 'spendingAccountTypePrev', label: 'Spending Account Type Prev', value: formData.spendingAccountTypePrev, rules: { required: formData.spendingAccountType === 'Yes' ? true : false } },
            { name: 'spendingAccountEligibleClasses', label: 'Spending Account Eligible Classes', value: formData.spendingAccountEligibleClasses, rules: { required: formData.spendingAccountIssued === 'Yes' ? true : false } },
            { name: 'spendingAccountPlanMemberEligibleClasses', label: 'Spending Account Plan Member Eligible Classes', value: formData.spendingAccountPlanMemberEligibleClasses, rules: { required: formData.spendingAccountIssued === 'Yes' ? true : false } },
            { name: 'spendingAccountAllocation', label: 'Spending Account Allocation', value: formData.spendingAccountAllocation, rules: { required: formData.spendingAccountIssued === 'Yes' ? true : false } },
            { name: 'spendingAccountSameAmountForAllAmount', label: 'Spending Account Same Amount For All Amount', value: formData.spendingAccountSameAmountForAllAmount, rules: { required: formData.spendingAccountAllocation === 'Same Amount For All' ? true : false } },
            { name: 'spendingAccountDifferentClassAAmount', label: 'Spending Account Different Class A Amount', value: formData.spendingAccountDifferentClassAAmount, rules: { required: formData.spendingAccountAllocation === 'Different Amount For Each Class' ? true : false } },
            { name: 'spendingAccountDifferentClassBAmount', label: 'Spending Account Different Class B Amount', value: formData.spendingAccountDifferentClassBAmount, rules: { required: false } },
            { name: 'spendingAccountAllocatedSingleHealthAmount', label: 'Spending Account Allocated Single Health Amount', value: formData.spendingAccountAllocatedSingleHealthAmount, rules: { required: false } },
            { name: 'spendingAccountAllocatedCoupleHealthAmount', label: 'Spending Account Allocated Couple Health Amount', value: formData.spendingAccountAllocatedCoupleHealthAmount, rules: { required: false } },
            { name: 'spendingAccountAllocatedFamilyHealthAmount', label: 'Spending Account Allocated Family Health Amount', value: formData.spendingAccountAllocatedFamilyHealthAmount, rules: { required: false } },
            { name: 'spendingAccountAllocatedOptedOutAmount', label: 'Spending Account Allocated Opted Out Amount', value: formData.spendingAccountAllocatedOptedOutAmount, rules: { required: false } },
            { name: 'spendingAccountInitialBenefitCalculation', label: 'Spending Account Initial Benefit Calculation', value: formData.spendingAccountInitialBenefitCalculation, rules: { required: false } },
            { name: 'spendingAccountInitialBenefitCalculationAmount', label: 'Spending Account Initial Benefit Calculation Amount', value: formData.spendingAccountInitialBenefitCalculationAmount, rules: { required: false } },
            { name: 'spendingAccountExistingBalancesTransferred', label: 'Spending Account Existing Balances Transferred', value: formData.spendingAccountExistingBalancesTransferred, rules: { required: false } },
            { name: 'spendingAccountSpecialInstructions', label: 'Spending Account Special Instructions', value: formData.spendingAccountSpecialInstructions, rules: { required: false } },
            { name: 'advisorBookletImprint', label: 'Advisor Booklet Imprint', value: formData.advisorBookletImprint, rules: { required: false } },
            { name: 'eapInforceLast31Days', label: 'EAP Inforce Last 31 Days', value: formData.eapInforceLast31Days, rules: { required: true } },
            { name: 'eapInforceLast31DaysProvider', label: 'EAP Inforce Last 31 Days Provider', value: formData.eapInforceLast31DaysProvider, rules: { required: false } },
            { name: 'groupAccountExecutive', label: 'Group Account Executive', value: formData.groupAccountExecutive, rules: { required: true } },
            { name: 'primaryBusinessName', label: 'Primary Business Name', value: formData.primaryBusinessName, rules: { required: true } },
            { name: 'primaryBusinessBranch', label: 'Primary Business Branch', value: formData.primaryBusinessBranch, rules: { required: true } },
            { name: 'primaryBusinessAdvisorNumber', label: 'Primary Business Advisor Number', value: formData.primaryBusinessAdvisorNumber, rules: { required: true } },
            { name: 'primaryBusinessCaseSplit', label: 'Primary Business Case Split', value: formData.primaryBusinessCaseSplit, rules: { required: true } },
            { name: 'primaryBusinessAdvisorName', label: 'Primary Business Advisor Name', value: formData.primaryBusinessAdvisorName, rules: { required: true } },
            { name: 'primaryBusinessAdvisorSIN', label: 'Primary Business Advisor SIN', value: formData.primaryBusinessAdvisorSIN, rules: { required: false } },
            { name: 'primaryBusinessStreet', label: 'Primary Business Street', value: formData.primaryBusinessStreet, rules: { required: true } },
            { name: 'primaryBusinessCity', label: 'Primary Business City', value: formData.primaryBusinessCity, rules: { required: true } },
            { name: 'primaryBusinessProvince', label: 'Primary Business Province', value: formData.primaryBusinessProvince, rules: { required: true } },
            { name: 'primaryBusinessPostalCode', label: 'Primary Business Postal Code', value: formData.primaryBusinessPostalCode, rules: { required: true } },
            { name: 'primaryBusinessPhone', label: 'Primary Business Phone', value: formData.primaryBusinessPhone, rules: { required: true } },
            { name: 'primaryBusinessFax', label: 'Primary Business Fax', value: formData.primaryBusinessFax, rules: { required: false } },
            { name: 'primaryBusinessEmail', label: 'Primary Business Email', value: formData.primaryBusinessEmail, rules: { required: true } },
            { name: 'secondaryBusinessName', label: 'Secondary Business Name', value: formData.secondaryBusinessName, rules: { required: false } },
            { name: 'secondaryBusinessBranch', label: 'Secondary Business Branch', value: formData.secondaryBusinessBranch, rules: { required: false } },
            { name: 'secondaryBusinessAdvisorNumber', label: 'Secondary Business Advisor Number', value: formData.secondaryBusinessAdvisorNumber, rules: { required: false } },
            { name: 'secondaryBusinessCaseSplit', label: 'Secondary Business Case Split', value: formData.secondaryBusinessCaseSplit, rules: { required: false } },
            { name: 'secondaryBusinessAdvisorName', label: 'Secondary Business Advisor Name', value: formData.secondaryBusinessAdvisorName, rules: { required: false } },
            { name: 'secondaryBusinessAdvisorSIN', label: 'Secondary Business Advisor SIN', value: formData.secondaryBusinessAdvisorSIN, rules: { required: false } },
            { name: 'secondaryBusinessStreet', label: 'Secondary Business Street', value: formData.secondaryBusinessStreet, rules: { required: false } },
            { name: 'secondaryBusinessCity', label: 'Secondary Business City', value: formData.secondaryBusinessCity, rules: { required: false } },
            { name: 'secondaryBusinessProvince', label: 'Secondary Business Province', value: formData.secondaryBusinessProvince, rules: { required: false } },
            { name: 'secondaryBusinessPostalCode', label: 'Secondary Business Postal Code', value: formData.secondaryBusinessPostalCode, rules: { required: false } },
            { name: 'secondaryBusinessPhone', label: 'Secondary Business Phone', value: formData.secondaryBusinessPhone, rules: { required: false } },
            { name: 'secondaryBusinessFax', label: 'Secondary Business Fax', value: formData.secondaryBusinessFax, rules: { required: false } },
            { name: 'secondaryBusinessEmail', label: 'Secondary Business Email', value: formData.secondaryBusinessEmail, rules: { required: false } },
            { name: 'commissionInformation', label: 'Commission Information', value: formData.commissionInformation, rules: { required: false } },
            { name: 'commissionOptionLifePercent', label: 'Commission Option Life Percent', value: formData.commissionOptionLifePercent, rules: { required: false } },
            { name: 'commissionOptionDepLifePercent', label: 'Commission Option Dep Life Percent', value: formData.commissionOptionDepLifePercent, rules: { required: false } },
            { name: 'commissionOptionADDPercent', label: 'Commission Option ADD Percent', value: formData.commissionOptionADDPercent, rules: { required: false } },
            { name: 'commissionOptionLTDPercent', label: 'Commission Option LTD Percent', value: formData.commissionOptionLTDPercent, rules: { required: false } },
            { name: 'commissionOptionEHCPercent', label: 'Commission Option EHC Percent', value: formData.commissionOptionEHCPercent, rules: { required: false } },
            { name: 'commissionOptionDentalPercent', label: 'Commission Option Dental Percent', value: formData.commissionOptionDentalPercent, rules: { required: false } },
            { name: 'customCommissionScale', label: 'Custom Commission Scale', value: formData.customCommissionScale, rules: { required: false } }, // disabled
            { name: 'hcsaCommissionScale', label: 'HCSA Commission Scale', value: formData.hcsaCommissionScale, rules: { required: false } },
            { name: 'equitableHealthConnectorWorldCareServices', label: 'Equitable Health Connector World Care Services', value: formData.equitableHealthConnectorWorldCareServices, rules: { required: false } },
            { name: 'equitableHealthConnectorFeelingBetterNow', label: 'Equitable Health Connector Feeling Better Now', value: formData.equitableHealthConnectorFeelingBetterNow, rules: { required: false } },
            { name: 'feelingBetterNow', label: 'Feeling Better Now', value: formData.feelingBetterNow, rules: { required: false } },
            { name: 'worldCareServices', label: 'World Care Services', value: formData.worldCareServices, rules: { required: false } },
            { name: 'comments', label: 'Comments', value: formData.comments, rules: { required: false } },
            { name: 'employeeCensus', label: 'Employee Census', value: files['employeeCensus'].length, rules: { required: true } },
            { name: 'preAuthorizedDebit', label: 'Pre Authorized Debit', value: files['preAuthorizedDebit'].length, rules: { required: false } },
            { name: 'license', label: 'License', value: files['license'].length, rules: { required: true } },
            { name: 'voidCheque', label: 'Void Cheque', value: files['voidCheque'].length || formData['voidChequeOnFile'], rules: { required: true } },
            { name: 'eAndOInsurance', label: 'E&O Insurance', value: files['eAndOInsurance'].length || formData['insuranceOnFile'], rules: { required: true } },
        ];

        if (formData.subsidiaries.length) {
            formData.subsidiaries.forEach((subsidiary, i) => {
                fieldValidations.push({ name: `subsidiary${i}Name`, label: 'Subsidiary Name', value: subsidiary.name, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}Division`, label: 'Subsidiary Address', value: subsidiary.division, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}Type`, label: 'Subsidiary Type', value: subsidiary.type, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}TypeOther`, label: 'Subsidiary Type Other', value: subsidiary.typeOther, rules: { required: false } });
                fieldValidations.push({ name: `subsidiary${i}FullAddress`, label: 'Subsidiary Full Address', value: subsidiary.fullAddress, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}PercentOwnership`, label: 'Subsidiary Percent Ownership', value: subsidiary.percentOwnership, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}BusinessDescription`, label: 'Subsidiary Nature Of Business', value: subsidiary.businessDescription, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}NumberOfEligibleMembers`, label: 'Subsidiary Number Of Eligible Members', value: subsidiary.numberOfEligibleMembers, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}NumberOfEligibleMembersParticipating`, label: 'Subsidiary Number Of Eligible Members Participating', value: subsidiary.numberOfEligibleMembersParticipating, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}ExplanationOfNonParticipation`, label: 'Subsidiary Explanation Of Non Participation', value: subsidiary.explanationOfNonParticipation, rules: { required: false } });
                fieldValidations.push({ name: `subsidiary${i}PlanAdministratorName`, label: 'Subsidiary Plan Administrator Name', value: subsidiary.planAdministratorName, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}PlanAdministratorPhone`, label: 'Subsidiary Plan Administrator Phone', value: subsidiary.planAdministratorPhone, rules: { required: true } });
                fieldValidations.push({ name: `subsidiary${i}PlanAdministratorFax`, label: 'Subsidiary Plan Administrator Fax', value: subsidiary.planAdministratorFax, rules: { required: false } });
                fieldValidations.push({ name: `subsidiary${i}CompanyEmail`, label: 'Subsidiary Company Email', value: subsidiary.companyEmail, rules: { required: true } });
            });
        }

        fieldValidations.forEach(field => {
            const error = validateField(field.label, field.value, field.rules);
            if (error) {
                newErrors[field.name] = error;
            }
        });

        console.log(newErrors);

        if (Object.keys(newErrors).length > 0) {
            const errorAttribute = Object.keys(newErrors)[0];
            const errorLabel = fieldValidations.find(field => field.name === errorAttribute).label;
            alert(formatSentenceCase(`${errorLabel} is required.`));
            setErrors(newErrors);
            setSubmitButtonDisabled(false);
            return;
        }

        const emailFormData = new FormData();
    
        // update file names with more description
        const employeeCensusFileName = `Employee Census - ${files['employeeCensus'][0].name.split('.')[0]}`;
        const preAuthorizedDebitFileName = `Pre Authorized Debit - ${!!files['preAuthorizedDebit'][0] ? files['preAuthorizedDebit'][0].name.split('.')[0] : ''}`;
        const licenseFileName = `License - ${files['license'][0].name.split('.')[0]}`;

        const currentDate = new Date().toISOString().split('T')[0];

        if (formData['voidChequeOnFile'] !== 'Yes') {
            const voidChequeFileName = `Void Cheque - ${files['voidCheque'][0].name.split('.')[0]}`;
            const voidChequeFileExtension = files['voidCheque'][0].name.split('.').pop();
            const voidChequeNewFileName = `${voidChequeFileName}_${currentDate}.${voidChequeFileExtension}`;
            emailFormData.append('voidCheque', files['voidCheque'][0], voidChequeNewFileName);
        }

        if (formData['insuranceOnFile'] !== 'Yes') {
            const eAndOInsuranceFileName = `E&O Insurance - ${files['eAndOInsurance'][0].name.split('.')[0]}`;
            const eAndOInsuranceFileExtension = files['eAndOInsurance'][0].name.split('.').pop();
            const eAndOInsuranceNewFileName = `${eAndOInsuranceFileName}_${currentDate}.${eAndOInsuranceFileExtension}`;
            emailFormData.append('eAndOInsurance', files['eAndOInsurance'][0], eAndOInsuranceNewFileName);
        }

        // update file names with the current date
        const employeeCensusFileExtension = files['employeeCensus'][0].name.split('.').pop();
        const preAuthorizedDebitFileExtension = !!files['preAuthorizedDebit'][0] ? files['preAuthorizedDebit'][0].name.split('.').pop() : '';
        const licenseFileExtension = files['license'][0].name.split('.').pop();
        
        const employeeCensusNewFileName = `${employeeCensusFileName}_${currentDate}.${employeeCensusFileExtension}`;
        const preAuthorizedDebitNewFileName = `${preAuthorizedDebitFileName}_${currentDate}.${preAuthorizedDebitFileExtension}`;
        const licenseNewFileName = `${licenseFileName}_${currentDate}.${licenseFileExtension}`;

        
        if (!!files['preAuthorizedDebit'][0]) {
            emailFormData.append('preAuthorizedDebit', files['preAuthorizedDebit'][0], preAuthorizedDebitNewFileName);
        }

        const formattedFormData = {
            ...formData,
            spendingAccountFees: formData['spendingAccountIssued'] === 'Yes' ? 'Admin Fees 8% Paid Claims' : '',
            maximumSubmissionPeriod: formData['spendingAccountIssued'] === 'Yes' ? '90 Days' : '',
            currentBenefitsCovered: !!formData.currentBenefitsCovered ? formData.currentBenefitsCovered.join(', ') : '',
        }

        emailFormData.append('employeeCensus', files['employeeCensus'][0], employeeCensusNewFileName);
        emailFormData.append('license', files['license'][0], licenseNewFileName);
        emailFormData.append('formContent', JSON.stringify(formattedFormData)); 

        const response = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL,
            data: emailFormData,
        });

        if (response.status === 200) {
            window.location.href = '/completed';
        } else {        
            alert('Something went wrong. Please try again later.');
        }
        setSubmitButtonDisabled(false);
    };
  
    const validateField = (name, value, rules) => {
        let error = null;

        if (rules.required && !value) {
            error = 'This field is required';
        } else if (rules.pattern && !rules.pattern.test(value)) {
            error = `Invalid ${name} format`;
        }
        return error;
    };

    const removeError = (name) => {
        const newErrors = { ...errors };
        delete newErrors[name];

        setErrors(newErrors);
    };

    const updateFormData = (event) => {
        // Update form data
        const newFormData = {
            ...formData,
            [event.target.name]: event.target.value,
        };
        
        removeError(event.target.name);
        setFormData(newFormData);
    };

    const handleFilesChange = (event, key) => {
        const targetFile = event.target.files[0];

        if (targetFile) {
            const validType = isValidFileType(targetFile);
            const validSize = isValidFileSize(targetFile);

            if (!validType) {
                event.target.value = null;
                alert('Invalid file type.');
                return;
            } else if (!validSize) {
                event.target.value = null;
                alert(' Please upload a file that is less than 20MB');
                return;
            } 
        } else {
            event.target.value = null;
            return;
        }

        // Remove error after file is updated
        const newErrors = { ...errors };
        delete newErrors[key];
        setErrors(newErrors);

        setFiles({
            ...files,
            [key]: [...event.target.files]
        });
    };

    const updateSubsidiaryData = (e, i, name) => {
        const subsidiaries = [...formData['subsidiaries']];
        subsidiaries[i][name] = e.target.value;
        const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
        removeError(`subsidiary${i}${capitalizedName}`);
        setFormData({ ...formData, subsidiaries })
    };

    const updateNotWorkingMemberData = (e, i, name) => {
        const membersNotWorking = [...formData['membersNotWorking']];
        membersNotWorking[i][name] = e.target.value;
        setFormData({ ...formData, membersNotWorking })
    };

    return (
        <form className="form-main" onSubmit={handleSubmit}>
            <Container>
                <Grid container rowSpacing={5}>
                    <Grid item className="form-header">
                        <h1>Application for Group Insurance</h1>
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">1. Plan Sponsor Details</label>
                        </Grid>
                        <Grid item xs={12}>
                            <p className="form-section-description">Please provide the full Plan sponsor name.  This is the company name issued by and legally registered with the Government of Canada.</p>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="companyName"
                                label=""
                                value={formData.companyName}
                                onChange={updateFormData}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelect 
                                name="legalStatus"
                                label="Legal Status"
                                options={[
                                    { value: 'Corporation', label: 'Corporation' },
                                    { value: 'Sole Proprietor', label: 'Sole Proprietor' },
                                    { value: 'Charitable or Not-for-Profit Organization', label: 'Charitable or Not-for-Profit Organization' },
                                    { value: 'Partnership', label: 'Partnership' },
                                    { value: 'Other', label: 'Other' },
                                ]}
                                value={formData.legalStatus}
                                onChange={updateFormData}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {
                                formData.legalStatus === 'Other' && (
                                    <FormInput
                                        name="legalStatusOther"
                                        label="Please specify:"
                                        style={{ marginLeft: 30 }}
                                        value={formData.legalStatusOther}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                )
                            }
                        </Grid>
            
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    name="streetAddress"
                                    label="Street Address (Canadian location is required)"
                                    value={formData.streetAddress}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    name="poBox"
                                    label="PO Box"
                                    value={formData.poBox}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    name="city"
                                    label="City"
                                    value={formData.city}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={6} spacing={2}>
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="province"
                                        label="Province"
                                        errors={errors}
                                        value={formData.province}
                                        onChange={updateFormData}
                                        options={provinceOptions.filter(province => province.label !== 'Quebec')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormInput
                                        name="postalCode"
                                        label="Postal Code"
                                        value={formData.postalCode}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    name="planSponsorName"
                                    label="Plan sponsor Contact (name of person with signing authority)"
                                    value={formData.planSponsorName}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={6} spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormMaskedInput 
                                        name="planSponsorPhone"
                                        label="Area Code, Phone #"
                                        mask="999-999-9999"
                                        value={formData.planSponsorPhone}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormMaskedInput
                                        name="planSponsorFax"
                                        label="Area Code, Fax #"
                                        mask="999-999-9999"
                                        value={formData.planSponsorFax}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    name="planAdministratorName"
                                    label="Plan Administrator"
                                    value={formData.planAdministratorName}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={6} spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormMaskedInput
                                        name="planAdministratorPhone"
                                        label="Area Code, Phone #"
                                        mask="999-999-9999"
                                        value={formData.planAdministratorPhone}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormMaskedInput
                                        name="planAdministratorFax"
                                        label="Area Code, Fax #"
                                        value={formData.planAdministratorFax}
                                        onChange={updateFormData}
                                        mask="999-999-9999"
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="companyEmail"
                                label="Plan Admin Email"
                                value={formData.companyEmail}
                                onChange={updateFormData}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="mailingAddress"
                                label="Mailing Address (if different from above)"
                                value={formData.mailingAddress}
                                onChange={updateFormData}
                                errors={errors}
                            />
                        </Grid>       
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">2. Subsidiary/ Affiliated/ Other Entities</label>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={10}>
                                <p className="form-section-description">How many subsidiary/affiliated companies that are applying for coverage under this policy?</p>
                            </Grid>
                            <Grid item xs={2}>
                                <FormSelect
                                    name="subsidiaryCount"
                                    options={[
                                        { value: '0', label: '0' },
                                        { value: '1', label: '1' },
                                        { value: '2', label: '2' },
                                        { value: '3', label: '3' },
                                    ]}
                                    value={formData.subsidiaryCount}
                                    onChange={e => {
                                        removeError('subsidiaryCount');
                                        setFormData({ 
                                            ...formData, 
                                            subsidiaryCount: e.target.value,
                                            subsidiaries: [...Array(parseInt(e.target.value))].map(() => ({}))
                                        })
                                    }}
                                    errors={errors}
                                    style={{ textAlign: 'center' }}
                                />
                            </Grid>
                        </Grid>
                        {
                            !!formData.subsidiaryCount && [...Array(parseInt(formData.subsidiaryCount))].map((_, i) => (
                                <Grid container item spacing={2} key={i}>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormInput
                                                name={`subsidiary${i}Name`}
                                                label={`Company #${i + 1} - Full name of entity as legally registered with the Government`}
                                                value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['name'] ? formData['subsidiaries'][i]['name'] : ''}
                                                onChange={e => updateSubsidiaryData(e, i, 'name')}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormInput
                                                name={`subsidiary${i}Division`}
                                                label="Division"
                                                value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['division'] ? formData['subsidiaries'][i]['division'] : ''}
                                                onChange={e => updateSubsidiaryData(e, i, 'division')}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormSelect
                                            name={`subsidiary${i}Type`}
                                            label="Type of Business"
                                            options={[
                                                { value: 'Subsidiary', label: 'Subsidiary' },
                                                { value: 'Affiliate', label: 'Affiliate' },
                                                { value: 'Other', label: 'Other' },
                                            ]}
                                            value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['type'] ? formData['subsidiaries'][i]['type'] : ''}
                                            onChange={e => updateSubsidiaryData(e, i, 'type')}
                                            errors={errors}
                                        />
                                    </Grid>
                                    {
                                        !!formData['subsidiaries'][i] && formData['subsidiaries'][i]['type'] === 'Other' &&
                                        <Grid item xs={12} style={{marginLeft: 20}}>
                                            <FormInput
                                                name={`subsidiary${i}TypeOther`}
                                                label="Please specify"
                                                value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['typeOther'] ? formData['subsidiaries'][i]['typeOther'] : ''}
                                                onChange={e => updateSubsidiaryData(e, i, 'typeOther')}
                                                errors={errors}
                                            />
                                        </Grid>
                                    }
                                
                                    <Grid item xs={12}>
                                        <FormInput
                                            name={`subsidiary${i}FullAddress`}
                                            label="Full Address (Unit, Street, City, Province, Postal Code)"
                                            value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['fullAddress'] ? formData['subsidiaries'][i]['fullAddress'] : ''}
                                            onChange={e => updateSubsidiaryData(e, i, 'fullAddress')}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormInput
                                            name={`subsidiary${i}PercentOwnership`}
                                            label="Legal or Financial Connection to Plan sponsor (% owned)"
                                            value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['percentOwnership'] ? formData['subsidiaries'][i]['percentOwnership'] : ''}
                                            onChange={e => updateSubsidiaryData(e, i, 'percentOwnership')}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormInput
                                            name={`subsidiary${i}BusinessDescription`}
                                            label="Nature of Business"
                                            value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['businessDescription'] ? formData['subsidiaries'][i]['businessDescription'] : ''}
                                            onChange={e => updateSubsidiaryData(e, i, 'businessDescription')}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormInput
                                                name={`subsidiary${i}NumberOfEligibleMembers`}
                                                label="Number of Eligible Plan Members"
                                                type="number"
                                                value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['numberOfEligibleMembers'] ? formData['subsidiaries'][i]['numberOfEligibleMembers'] : ''}
                                                onChange={e => updateSubsidiaryData(e, i, 'numberOfEligibleMembers')}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormInput
                                                name={`subsidiary${i}NumberOfEligibleMembersParticipating`}
                                                label="Number of Eligible Plan Members Participating"
                                                type="number"
                                                value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['numberOfEligibleMembersParticipating'] ? formData['subsidiaries'][i]['numberOfEligibleMembersParticipating'] : ''}
                                                onChange={e => updateSubsidiaryData(e, i, 'numberOfEligibleMembersParticipating')}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormInput
                                            name={`subsidiary${i}ExplanationOfNonParticipation`}
                                            label="If any Eligible Plan members are not applying, please explain"
                                            value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['explanationOfNonParticipation'] ? formData['subsidiaries'][i]['explanationOfNonParticipation'] : ''}
                                            onChange={e => updateSubsidiaryData(e, i, 'explanationOfNonParticipation')}
                                            errors={errors}
                                        />
                                    </Grid> 
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormInput
                                                name={`subsidiary${i}PlanAdministratorName`}
                                                label="Plan Administrator Name"
                                                value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['planAdministratorName'] ? formData['subsidiaries'][i]['planAdministratorName'] : ''}
                                                onChange={e => updateSubsidiaryData(e, i, 'planAdministratorName')}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid container item xs={12} sm={6} spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <FormMaskedInput
                                                    name={`subsidiary${i}PlanAdministratorPhone`}
                                                    label="Phone No."
                                                    mask={'999-999-9999'}
                                                    value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['planAdministratorPhone'] ? formData['subsidiaries'][i]['planAdministratorPhone'] : ''}
                                                    onChange={e => updateSubsidiaryData(e, i, 'planAdministratorPhone')}
                                                    errors={errors}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormMaskedInput
                                                    name={`subsidiary${i}PlanAdministratorFax`}
                                                    label="Fax No."
                                                    mask={'999-999-9999'}
                                                    value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['planAdministratorFax'] ? formData['subsidiaries'][i]['planAdministratorFax'] : ''}
                                                    onChange={e => updateSubsidiaryData(e, i, 'planAdministratorFax')}
                                                    errors={errors}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormInput
                                                name={`subsidiary${i}CompanyEmail`}
                                                label="Full e-mail Address"
                                                type='email'
                                                value={!!formData['subsidiaries'][i] && formData['subsidiaries'][i]['companyEmail'] ? formData['subsidiaries'][i]['companyEmail'] : ''}
                                                onChange={e => updateSubsidiaryData(e, i, 'companyEmail')}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">3. Requested Effective Date Of Group Policy</label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormInput
                                name="effectiveDate"
                                label="Effective Date"
                                type="date"
                                disabled={true}
                                value={formData['effectiveDate']}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <p>Please Note:  Termination of current coverage should only be initiated after you receive written confirmation of  Equitable Life's approval of this Application. </p>
                        </Grid>
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">4. Other Group Coverage</label>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelect
                                name="coverageInforced"
                                label="a) Is there group insurance coverage currently inforce or was inforce during the last 31 days?"
                                value={formData['coverageInforced']}
                                onChange={updateFormData}
                                options={yesNoOptions}
                                errors={errors}
                            />
                        </Grid>
                        {
                            formData['coverageInforced'] === 'Yes' && (
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <p>Please note: If coverage being applied for will replace group coverage now enforce or enforce during the last 31 days with another carrier, Equitable Life requires a copy of that carrier's policy or booklet.  </p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormInput
                                            name="currentInsurerPolicy"
                                            label="Current or Previous Insurer(s) and Policy #(s):"
                                            value={formData['currentInsurerPolicy']}
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormInput
                                                name="currentInsurerEffectiveDate"
                                                label="Effective Date"
                                                type="date"
                                                value={formData['currentInsurerEffectiveDate']}
                                                onChange={updateFormData}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormInput
                                                name="currentInsurerTerminationDate"
                                                label="Termination Date"
                                                type="date"
                                                value={formData['currentInsurerTerminationDate']}
                                                onChange={updateFormData}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={12}>
                                                <label>Benefits Covered</label>
                                            </Grid>
                                            <Grid container item spacing={2}>
                                                {
                                                    currentBenefitsCoveredOptions.map((item, index) => (
                                                        <Grid item xs={12} sm={6} md={4}>
                                                            <FormControlLabel key={index} checked={!!formData['currentBenefitsCovered'] && formData['currentBenefitsCovered'].includes(item.value)} control={<Checkbox />} label={item.label} onClick={
                                                                () => {
                                                                    if (!!formData['currentBenefitsCovered'] && formData['currentBenefitsCovered'].includes(item.value)) {
                                                                        formData['currentBenefitsCovered'] = formData['currentBenefitsCovered'].filter((value) => value !== item.value);
                                                                    } else {
                                                                        if (!formData['currentBenefitsCovered']) {
                                                                            formData['currentBenefitsCovered'] = [];
                                                                        }
                                                                        formData['currentBenefitsCovered'].push(item.value);
                                                                    }
                                                                    setFormData({ ...formData });
                                                                }
                                                            } />
                                                        </Grid>
                                                    ))
                                                }                                   
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p><b>Please Note:</b> If there are Dependents currently receiving survivor benefits, please submit an enrolment card to ensure continuance of coverage and include the termination date of benefits.</p>
                                        </Grid>
                                    </Grid>
                                </React.Fragment> 
                            )
                        }
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">5. Eligible Plan Members</label>
                        </Grid>
                        <Grid item xs={12}>
                            <p><b>Please Note:</b> In order to be eligible for coverage under the Group Policy, "<b>Eligible Plan members</b>" must be on the Plan sponsor's payroll, and reside and work in Canada on a permanent, non-seasonal, full-time basis. Temporary plan members are not eligible for coverage under the Group Policy.</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p><b>Minimum Hours:</b> Eligible Plan members must work a minimum of 20 hours per week on a regularly scheduled basis </p>
                        </Grid>
                        <Grid item xs={12}>
                            <p><b>Workers' Compensation:</b> Does provincial legislation (in the province where the plan member works) require workers' compensation be in place for any of the eligible Plan members?   </p>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelect
                                name="workersCompensation"
                                label=""
                                value={formData['workersCompensation']}
                                onChange={updateFormData}
                                options={yesNoOptions}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <p><b>Payroll:</b> Do all eligible Plan members receive T4s?  </p>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelect
                                name="payrollEligibility"
                                label=""
                                value={formData['payrollEligibility']}
                                onChange={updateFormData}
                                options={yesNoOptions}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <p>Does the Plan sponsor remit to the appropriate Agencies the following for all Eligible Plan members: </p>
                        </Grid>
                        <Grid container item spacing={2}>
                            {
                                planSponsorRemitAgencies.map((item, index) => (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControlLabel key={index} checked={!!formData['planSponsoredAgency'] && formData['planSponsoredAgency'].includes(item.value)} control={<Checkbox />} label={item.label} onClick={
                                            () => {
                                                if (!!formData['planSponsoredAgency'] && formData['planSponsoredAgency'].includes(item.value)) {
                                                    formData['planSponsoredAgency'] = formData['planSponsoredAgency'].filter((value) => value !== item.value);
                                                } else {
                                                    if (!formData['planSponsoredAgency']) {
                                                        formData['planSponsoredAgency'] = [];
                                                    }
                                                    formData['planSponsoredAgency'].push(item.value);
                                                }
                                                setFormData({ ...formData });
                                            }
                                        } />
                                    </Grid>
                                ))
                            }   
                            { !formData['planSponsoredAgency'].length ? 
                                <Grid item xs={12}>
                                    <FormInput
                                        name="planSponsoredAgencyOther"
                                        label="If the Plan sponsor does not pay any of the above, please explain:"
                                        value={formData['planSponsoredAgencyOther']}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid> : null
                            }
                        </Grid>
                    </Grid>      
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">6. Plan Members Not Actively At Work</label>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={10}>
                                <p className="form-section-description">Are there any Eligible plan members currently away from work for reasons other than vacation?</p>
                            </Grid>
                            <Grid item xs={2}>
                                <FormSelect
                                    name="nonWorkingMemberCount"
                                    label=""
                                    value={formData['nonWorkingMemberCount']}
                                    onChange={e => {
                                        removeError('nonWorkingMemberCount');
                                        setFormData({ 
                                            ...formData, 
                                            nonWorkingMemberCount: e.target.value,
                                            membersNotWorking: [...Array(parseInt(e.target.value))].map(() => ({}))
                                        })
                                    }}
                                    options={[
                                        { value: '0', label: '0' },
                                        { value: '1', label: '1' },
                                        { value: '2', label: '2' },
                                        { value: '3', label: '3' },
                                    ]}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        { !!formData['nonWorkingMemberCount'] && [...Array(parseInt(formData['nonWorkingMemberCount']))].map((_, i) => ( 
                            <Grid container item spacing={2} key={i}>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormInput
                                            name={`planMember${i + 1}LastName`}
                                            label={`Last Name #${i + 1}`}
                                            value={formData['membersNotWorking'][i].lastName}
                                            onChange={e => updateNotWorkingMemberData(e, i, 'lastName')}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormInput
                                            name={`planMember${i + 1}FirstName`}
                                            label={`First Name #${i + 1}`}
                                            value={formData['membersNotWorking'][i].firstName}
                                            onChange={e => updateNotWorkingMemberData(e, i, 'firstName')}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormInput
                                        name={`planMember${i + 1}DateOfBirth`}
                                        label="Date of Birth"
                                        type='date'
                                        value={formData['membersNotWorking'][i].dateOfBirth}
                                        onChange={e => updateNotWorkingMemberData(e, i, 'dateOfBirth')}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect
                                        name={`planMember${i + 1}Reason`}
                                        label="Reason for Plan member's Absence:"
                                        value={formData['membersNotWorking'][i].reason}
                                        onChange={e => updateNotWorkingMemberData(e, i, 'reason')}
                                        options={[
                                            { value: 'Disability', label: 'Disability' },
                                            { value: 'Workers Comp', label: 'Workers Comp' },
                                            { value: 'Maternity', label: 'Maternity' },
                                            { value: 'Other', label: 'Other' },
                                        ]}
                                        errors={errors}
                                    />
                                </Grid>
                                { formData['membersNotWorking'][i].reason === 'Other' && (
                                    <Grid item xs={12}>
                                        <FormInput
                                            name={`planMember${i + 1}ReasonOther`}
                                            label="Please provide details:"
                                            style={{ marginLeft: 30 }}
                                            value={formData['membersNotWorking'][i].reasonOther}
                                            onChange={e => updateNotWorkingMemberData(e, i, 'reasonOther')}
                                            errors={errors}
                                        />
                                    </Grid>
                                )}
                                <Grid container item spacing={2}>
                                    {
                                        formData['membersNotWorking'][i].reason !== 'Maternity' && 
                                        <Grid item xs={12} sm={6}>
                                            <FormInput
                                                name={`planMember${i + 1}DateDisabled`}
                                                label="Date Plan member Became Disabled:"
                                                type='date'
                                                value={formData['membersNotWorking'][i].dateDisabled}
                                                onChange={e => updateNotWorkingMemberData(e, i, 'dateDisabled')}
                                                errors={errors}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12} sm={6}>
                                        <FormInput
                                            name={`planMember${i + 1}dateLastWorked`}
                                            label="Date Last Worked:"
                                            type='date'
                                            value={formData['membersNotWorking'][i].dateLastWorked}
                                            onChange={e => updateNotWorkingMemberData(e, i, 'dateLastWorked')}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <label>Date Plan member is Expected to Return to Full-Time Work:</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormInput
                                        name={`planMember${i + 1}expectedToReturn`}
                                        label=""
                                        type='date'
                                        value={formData['membersNotWorking'][i].expectedToReturn}
                                        onChange={e => updateNotWorkingMemberData(e, i, 'expectedToReturn')}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={6} spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel 
                                            checked={formData['membersNotWorking'][i].expectedToReturn === 'Unknown'} 
                                            onClick={_ => {
                                                if (formData['membersNotWorking'][i].expectedToReturn === 'Unknown') {
                                                    updateNotWorkingMemberData({ target: { value: '' } }, i, 'expectedToReturn')
                                                } else {
                                                    updateNotWorkingMemberData({ target: { value: 'Unknown' } }, i, 'expectedToReturn')
                                                }
                                            }}
                                            control={<Checkbox name={`planMember${i + 1}expectedToReturn`} color="primary" />} 
                                            label="Unknown" 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            checked={formData['membersNotWorking'][i].expectedToReturn === 'Never Returning'}
                                            onClick={_ => {
                                                if (formData['membersNotWorking'][i].expectedToReturn === 'Never Returning') {
                                                    updateNotWorkingMemberData({ target: { value: '' } }, i, 'expectedToReturn')
                                                } else {
                                                    updateNotWorkingMemberData({ target: { value: 'Never Returning' } }, i, 'expectedToReturn')
                                                }
                                            }}
                                            control={<Checkbox name={`planMember${i + 1}expectedToReturn`} color="primary" />}
                                            label="Never Returning"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect
                                        name={`planMember${i + 1}CollectingReason`}
                                        label="Is this Plan member collecting:"
                                        value={formData['membersNotWorking'][i].collectingReason}
                                        onChange={e => updateNotWorkingMemberData(e, i, 'collectingReason')}
                                        options={[
                                            { value: 'Yes, STD', label: 'Yes, STD' },
                                            { value: 'Yes, LTD', label: 'Yes, LTD' },
                                            { value: 'No, Applied but not approved', label: 'No, Applied but not approved' },
                                            { value: 'No, Denied', label: 'No, Denied' },
                                            { value: 'No, Other', label: 'No, Other' },
                                        ]}
                                        errors={errors}
                                    />


                                </Grid>
                                {
                                    formData['membersNotWorking'][i].collectingReason === 'No, Other' && (
                                        <Grid item xs={12} style={{ marginLeft: 20 }}>
                                            <FormInput
                                                name={`planMember${i + 1}CollectingReasonOther`}
                                                label="Please provide details"
                                                value={formData['membersNotWorking'][i].collectingReasonOther}
                                                onChange={e => updateNotWorkingMemberData(e, i, 'collectingReasonOther')}
                                                errors={errors}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    formData['membersNotWorking'][i].reason !== 'Maternity' && 
                                    <Grid item xs={12}>
                                        <FormSelect
                                            name={`planMember${i + 1}PreviouslyAppliedLifeWaiver`}
                                            label="If Disabled, has the Plan member applied for Life Waiver with the previous insurance carrier?"
                                            options={yesNoOptions}
                                            value={formData['membersNotWorking'][i].previouslyAppliedLifeWaiver}
                                            onChange={e => updateNotWorkingMemberData(e, i, 'previouslyAppliedLifeWaiver')}
                                            errors={errors}
                                        />
                                    </Grid>
                                }
                                {
                                    formData['membersNotWorking'][i].previouslyAppliedLifeWaiver === 'Yes' && 
                                    <Grid item xs={12} style={{ marginLeft: 20 }}>
                                        <FormSelect
                                            name={`planMember${i + 1}AcceptedLifeWaiver`}
                                            label='Has the Plan member been accepted for Life Waiver?'
                                            options={[
                                                { value: 'Yes', label: 'Yes' },
                                                { value: 'Still in Approval process', label: 'Still in Approval process' },
                                                { value: 'No, denied', label: 'No, denied' },
                                            ]}
                                            value={formData['membersNotWorking'][i].acceptedLifeWaiver}
                                            onChange={e => updateNotWorkingMemberData(e, i, 'acceptedLifeWaiver')}
                                            errors={errors}
                                        />
                                    </Grid>
                                }

                                <Grid item xs={6}>
                                    <FormInput
                                        name={`planMember${i + 1}TerminationDate`}
                                        label="Terminate all benefits on"
                                        type='date'
                                        value={formData['membersNotWorking'][i].terminationDate}
                                        onChange={e => updateNotWorkingMemberData(e, i, 'terminationDate')}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">7. Benefit Package Selection For Each Class</label>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={2}>
                                <label>Class</label>
                            </Grid>
                            <Grid item xs={4}>
                                <label>Benefit Package Selection</label>
                            </Grid>
                            <Grid item xs={3}>
                                <label>LTD included</label>
                            </Grid>
                            <Grid item xs={3}>
                                {
                                    (!!formData['benefitClassALives'] || !!formData['benefitClassBLives']) && <label>Lives</label>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={2} style={{marginTop: 10}}>
                                <span>A</span>
                            </Grid>
                            <Grid item xs={4}>
                                <FormSelect
                                    name="benefitClassASelection"
                                    label=""
                                    value={formData['benefitClassASelection'] ?? ''}
                                    disabled={true}
                                    errors={errors}
                                    options={[
                                        { 'label': 'Bronze', 'value': 'Bronze' },
                                        { 'label': 'Silver', 'value': 'Silver' },
                                        { 'label': 'Gold', 'value': 'Gold' },
                                        { 'label': 'Platinum', 'value': 'Platinum' },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormSelect
                                    name="benefitClassALtdIncluded"
                                    label=""
                                    value={formData['benefitClassALtdIncluded'] ?? ''}
                                    disabled={true}
                                    errors={errors}
                                    options={yesNoOptions}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {
                                    !!formData['benefitClassALives'] &&
                                    <FormInput
                                        name="benefitClassALives"
                                        label=""
                                        value={formData['benefitClassALives'] ?? ''}
                                        disabled={true}
                                        errors={errors}
                                    />
                                }
                            </Grid>
                        </Grid>
                        {
                            showClassB &&
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={2} style={{marginTop: 10}}>
                                    <span>B</span>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormSelect
                                        name="benefitClassBSelection"
                                        label=""
                                        value={formData['benefitClassBSelection'] ?? ''}
                                        disabled={true}
                                        errors={errors}
                                        options={[
                                            { 'label': '', 'value': '' },
                                            { 'label': 'Bronze', 'value': 'Bronze' },
                                            { 'label': 'Silver', 'value': 'Silver' },
                                            { 'label': 'Gold', 'value': 'Gold' },
                                            { 'label': 'Platinum', 'value': 'Platinum' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormSelect
                                        name="benefitClassBLtdIncluded"
                                        label=""
                                        value={formData['benefitClassBLtdIncluded'] ?? ''}
                                        disabled={true}
                                        errors={errors}
                                        options={[
                                            { 'label': '', 'value': '' },
                                            { 'label': 'Yes', 'value': 'Yes' },
                                            { 'label': 'No', 'value': 'No' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {
                                        !!formData['benefitClassBLives'] &&
                                        <FormInput
                                            name="benefitClassBLives"
                                            label=""
                                            value={formData['benefitClassBLives'] ?? ''}
                                            disabled={true}
                                            errors={errors}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">8. Division Requirements And Definitions</label>
                        </Grid>
                        <Grid item xs={12}>
                            <p>Only one division will be set up, using the Plan sponsor name for the billings and plan member cards unless otherwise indicated. <b>Please note:</b> a division is required for each class.</p>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={2}>
                                <b>Class</b>
                            </Grid>
                            <Grid item xs={5}>
                                <b>Division Name</b>
                            </Grid>
                            <Grid item xs={5}>
                                <b>Division Name to be shown on billings</b>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={2}>
                                <label>A</label>
                            </Grid>
                            <Grid item xs={5}>
                                <FormInput
                                    name="divisionA"
                                    value={formData['divisionA']}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                    
                            <Grid item xs={5}>
                                <FormSelect
                                    name="divisionANameShown"
                                    label=""
                                    options={[
                                        { value: 'Yes', label: 'Yes' },
                                        { value: 'No, use the legal name of the Plan sponsor', label: 'No, use the legal name of the Plan sponsor' },
                                    ]}
                                    value={formData['divisionANameShown']}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                                            
                        </Grid>
                        {
                            showClassB && 
                            <Grid container item spacing={2}>
                                <Grid item xs={2}>
                                    <label>B</label>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormInput
                                        name="divisionB"
                                        value={formData['divisionB']}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormSelect
                                        name="divisionBNameShown"
                                        label=""
                                        options={[
                                            { value: '', label: '' },
                                            { value: 'Yes', label: 'Yes' },
                                            { value: 'No, use the legal name of the Plan sponsor', label: 'No, use the legal name of the Plan sponsor' },
                                        ]}
                                        value={formData['divisionBNameShown']}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">9. Employer Contribution To Premiums</label>
                        </Grid>
                        <Grid item xs={12}>
                            <p>100% of all Eligible present and future plan members must participate</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p>What percentage is the Plan sponsor contribution to premiums (for benefits other than LTD) for Class A under this Plan? </p>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormInput
                                    name="employerContributionLife"
                                    label="Life"
                                    type="number"
                                    suffix='%'
                                    value={formData['employerContributionLife']}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormInput
                                    name="employerContributionADD"
                                    label="AD&D"
                                    type="number"
                                    suffix='%'
                                    value={formData['employerContributionADD']}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormInput
                                    name="employerContributionDependentLife"
                                    label="Dependent Life"
                                    type="number"
                                    suffix='%'
                                    value={formData['employerContributionDependentLife']}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormInput
                                    name="employerContributionHealth"
                                    label="Health"
                                    type="number"
                                    suffix='%'
                                    value={formData['employerContributionHealth']}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormInput
                                    name="employerContributionDental"
                                    label="Dental"
                                    type="number"
                                    suffix='%'
                                    value={formData['employerContributionDental']}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        {
                            showClassB && (

                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <p>What percentage is the Plan sponsor contribution to premiums (for benefits other than LTD) for Class B under this Plan? </p>
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12} sm={4} md={2}>
                                            <FormInput
                                                name="employerContributionLifeClassB"
                                                label="Life"
                                                type="number"
                                                suffix='%'
                                                value={formData['employerContributionLifeClassB']}
                                                onChange={updateFormData}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2}>
                                            <FormInput
                                                name="employerContributionADDClassB"
                                                label="AD&D"
                                                type="number"
                                                suffix='%'
                                                value={formData['employerContributionADDClassB']}
                                                onChange={updateFormData}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2}>
                                            <FormInput
                                                name="employerContributionDependentLifeClassB"
                                                label="Dependent Life"
                                                type="number"
                                                suffix='%'
                                                value={formData['employerContributionDependentLifeClassB']}
                                                onChange={updateFormData}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12} sm={4} md={2}>
                                            <FormInput
                                                name="employerContributionHealthClassB"
                                                label="Health"
                                                type="number"
                                                suffix='%'
                                                value={formData['employerContributionHealthClassB']}
                                                onChange={updateFormData}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2}>
                                            <FormInput
                                                name="employerContributionDentalClassB"
                                                label="Dental"
                                                type="number"
                                                suffix='%'
                                                value={formData['employerContributionDentalClassB']}
                                                onChange={updateFormData}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                        <Grid item xs={12}>
                            <p><b>Please Note:</b> For all Plans: Any eligible present or future plan member or dependent not applying within 31 days of their Eligibility Date will be considered a late applicant and are subject to insurability requirements and approval.</p>
                        </Grid>
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">10. Health Care Spending Account</label>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelect
                                name="spendingAccountIssued"
                                label="Is a Health care spending account being issued for this group?"
                                value={formData['spendingAccountIssued'] ?? ''}
                                onChange={updateFormData}
                                errors={errors}
                                options={yesNoOptions}
                            />
                        </Grid>
                        {
                            formData['spendingAccountIssued'] === 'Yes' &&
                            <React.Fragment>
                                <Grid container item spacing={2}>
                                    <Grid item xs={12} sm={3}>
                                        <label>Type of HCSA Plan:</label>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <p><b>Use it or Lose it / Pay as you go HCSA (billed in Arrears)</b></p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect
                                        name="spendingAccountType"
                                        label="Is this a Start Up or Take Over Plan?"
                                        value={formData['spendingAccountType'] ?? ''}
                                        onChange={updateFormData}
                                        errors={errors}
                                        options={[
                                            { label: 'Start Up Plan (no prior carrier)', value: 'Start Up Plan' },
                                            { label: 'Take Over Plan (from prior carrier)', value: 'Take Over Plan' },
                                        ]}
                                    />
                                </Grid>
                                {
                                    formData['spendingAccountType'] === 'Take Over Plan' &&
                                    <Grid item xs={12} style={{ marginLeft: 20 }}>
                                        <FormSelect
                                            name='spendingAccountTypePrev'
                                            label='Previous Plan Type'
                                            value={formData['spendingAccountTypePrev'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                            options={[
                                                { label: 'Balance Carry Forward', value: 'Balance Carry Forward' },
                                                { label: 'Use it or Lose it', value: 'Use it or Lose it' },
                                                { label: 'Claims Carry Forward', value: 'Claims Carry Forward' },
                                            ]}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <label className="form-section-label-md">1. Eligible Classes and Plan members:</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <label>A) The following Class(es) will be eligible:  </label>
                                    <FormSelect
                                        name='spendingAccountEligibleClasses'
                                        value={formData['spendingAccountEligibleClasses'] ?? ''}
                                        onChange={updateFormData}
                                        errors={errors}
                                        options={
                                            !!showClassB ? 
                                                [
                                                    { label: 'Class A Only', value: 'Class A Only' },
                                                    { label: 'Class B Only', value: 'Class B Only' },
                                                    { label: 'Class A and Class B', value: 'Class A and Class B' },                                     
                                                ] 
                                            :
                                                [
                                                    { label: 'Class A Only', value: 'Class A Only' },
                                                ] 
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label>B) And for Plan members in the Eligible Class(es): </label>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect
                                        name="spendingAccountPlanMemberEligibleClasses"
                                        value={formData['spendingAccountPlanMemberEligibleClasses'] ?? ''}
                                        onChange={updateFormData}
                                        errors={errors}
                                        options={[
                                            { label: 'All Plan members, including those with spousal waivers', value: 'All Plan members, including those with spousal waivers' },
                                            { label: 'Only Plan members with both Health and Dental coverage', value: 'Only Plan members with both Health and Dental coverage' },
                                            { label: 'Only Plan members with either Health or Dental coverage', value: 'Only Plan members with either Health or Dental coverage' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label className="form-section-label-md">2. Annual Allocation Amount: </label>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect
                                        name="spendingAccountAllocation"
                                        value={formData['spendingAccountAllocation'] ?? ''}
                                        onChange={updateFormData}
                                        errors={errors}
                                        options={[
                                            { label: 'Same amount for all Plan members/Classes', value: 'Same amount for all Plan members/Classes' },
                                            { label: 'Different amounts allocated per Class', value: 'Different amounts allocated per Class' },
                                            { label: 'Different amounts allocated based on Single/Couple/Family Coverage', value: 'Different amounts allocated based on Single/Couple/Family Coverage' },
                                        ]}
                                    />
                                </Grid>             
                                { !!formData['spendingAccountAllocation'] && formData['spendingAccountAllocation'] === 'Same amount for all Plan members/Classes' &&
                                    <Grid item xs={6}>
                                        <FormInput
                                            name="spendingAccountSameAmountForAllAmount"
                                            type='number'
                                            value={formData['spendingAccountSameAmountForAllAmount'] ?? ''}
                                            prefix='$'
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                }


                                {
                                    formData['spendingAccountAllocation'] === 'Different amounts allocated per Class' &&
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <span>Allocated amount per Class:</span>
                                        </Grid>
                                        {
                                            (formData['spendingAccountEligibleClasses'] === 'Class A Only' || formData['spendingAccountEligibleClasses'] === 'Class A and Class B') &&
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid container item xs={3} spacing={2}>
                                                    <Grid item xs={4}>
                                                        <span>Class</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>A</span>
                                                    </Grid>                                               
                                                </Grid>
                                                <Grid container item xs={9}>
                                                    <Grid item xs={6}>
                                                        <FormInput
                                                            name="spendingAccountDifferentClassAAmount"
                                                            value={formData['spendingAccountDifferentClassAAmount'] ?? ''}
                                                            type='number'
                                                            prefix='$'
                                                            onChange={updateFormData}
                                                            errors={errors}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                            (formData['spendingAccountEligibleClasses'] === 'Class B Only' || formData['spendingAccountEligibleClasses'] === 'Class A and Class B') &&
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid container item xs={3} spacing={2}>
                                                    <Grid item xs={4}>
                                                        <span>Class</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>B</span>
                                                    </Grid>                                               
                                                </Grid>
                                                <Grid container item xs={9}>
                                                    <Grid item xs={6}>
                                                        <FormInput
                                                            name="spendingAccountDifferentClassBAmount"
                                                            value={formData['spendingAccountDifferentClassBAmount'] ?? ''}
                                                            type='number'
                                                            prefix='$'
                                                            onChange={updateFormData}
                                                            errors={errors}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    formData['spendingAccountAllocation'] === 'Different amounts allocated based on Single/Couple/Family Coverage' &&
                                    <React.Fragment>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <span>Single Health and/or Dental Coverage</span>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormInput
                                                    name="spendingAccountAllocatedSingleHealthAmount"
                                                    value={formData['spendingAccountAllocatedSingleHealthAmount'] ?? ''}
                                                    type='number'
                                                    prefix='$'
                                                    onChange={updateFormData}
                                                    errors={errors}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <span>Couple Health and/or Dental Coverage</span>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormInput
                                                    name="spendingAccountAllocatedCoupleHealthAmount"
                                                    value={formData['spendingAccountAllocatedCoupleHealthAmount'] ?? ''}
                                                    type='number'
                                                    prefix='$'
                                                    onChange={updateFormData}
                                                    errors={errors}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <span>Family Health and/or Dental Coverage</span>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormInput
                                                    name="spendingAccountAllocatedFamilyHealthAmount"
                                                    value={formData['spendingAccountAllocatedFamilyHealthAmount'] ?? ''}
                                                    type='number'
                                                    prefix='$'
                                                    onChange={e => setFormData({ ...formData, spendingAccountAllocatedFamilyHealthAmount: e.target.value })}
                                                    errors={errors}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <span>Plan members who opted out of Health and Dental</span>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormInput
                                                    name="spendingAccountAllocatedOptedOutAmount"
                                                    value={formData['spendingAccountAllocatedOptedOutAmount'] ?? ''}
                                                    type='number'
                                                    prefix='$'
                                                    onChange={e => setFormData({ ...formData, spendingAccountAllocatedOptedOutAmount: e.target.value })}
                                                    errors={errors}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p>*Note:  Plan members who enrol in Couple or Family Health or Dental but either select Single coverage or Opt Out of the other benefit will receive the higher HCSA amount</p>
                                        </Grid>
                                    </React.Fragment>
                                }
                            
                                <Grid item xs={12}>
                                    <label className="form-section-label-md">3. Fees to be charged for the Health Care Spending Account: </label>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput
                                        name="spendingAccountFees"
                                        value={formData['spendingAccountIssued'] === 'Yes' ? 'Admin Fees 8% Paid Claims' : ''}
                                        disabled={true}
                                        errors={errors}
                                    />
                                    <p>*Note: Comissions can be located on the Advisor Agreement form</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <label className="form-section-label-md">4. Initial Benefit Period: </label>
                                </Grid>
                                <Grid item xs={12}>
                                    <p>A) The Initial Benefit Period will start on the Effective Date of the policy and end the last day of the calendar year</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <label>B) The initial Benefit Period Amount is to be calculated:</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        checked={!!formData['spendingAccountInitialBenefitCalculation'] && formData['spendingAccountInitialBenefitCalculation'] === 'Full Annual Allocation for existing plan members and prorated for future new plan members'}
                                        onClick={_ => {
                                            if (formData['spendingAccountInitialBenefitCalculation'] === 'Full Annual Allocation for existing plan members and prorated for future new plan members') {
                                                setFormData({ ...formData, spendingAccountInitialBenefitCalculation: '' })
                                            } else {
                                                setFormData({ ...formData, spendingAccountInitialBenefitCalculation: 'Full Annual Allocation for existing plan members and prorated for future new plan members' })
                                            }
                                        }}
                                        control={<Checkbox name="spendingAccountInitialBenefitCalculation" color="primary" />}
                                        label="Full Annual Allocation for existing plan members and prorated for future new plan members"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        checked={!!formData['spendingAccountInitialBenefitCalculation'] && formData['spendingAccountInitialBenefitCalculation'] === 'Full Annual Allocation for existing plan members and future new plan members'}
                                        onClick={_ => {
                                            if (formData['spendingAccountInitialBenefitCalculation'] === 'Full Annual Allocation for existing plan members and future new plan members') {
                                                setFormData({ ...formData, spendingAccountInitialBenefitCalculation: '' })
                                            } else {
                                                setFormData({ ...formData, spendingAccountInitialBenefitCalculation: 'Full Annual Allocation for existing plan members and future new plan members' })
                                            }
                                        }}
                                        control={<Checkbox name="spendingAccountInitialBenefitCalculation" color="primary" />}
                                        label="Full Annual Allocation for existing plan members and future new plan members"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        checked={!!formData['spendingAccountInitialBenefitCalculation'] && formData['spendingAccountInitialBenefitCalculation'] === 'Same Flat Allocation for all plan members for the Initial Benefit Period'}
                                        onClick={_ => {
                                            if (formData['spendingAccountInitialBenefitCalculation'] === 'Same Flat Allocation for all plan members for the Initial Benefit Period') {
                                                setFormData({ ...formData, spendingAccountInitialBenefitCalculation: '' })
                                            } else {
                                                setFormData({ ...formData, spendingAccountInitialBenefitCalculation: 'Same Flat Allocation for all plan members for the Initial Benefit Period' })
                                            }
                                        }}
                                        control={<Checkbox name="spendingAccountInitialBenefitCalculation" color="primary" />}
                                        label="Same Flat Allocation for all plan members for the Initial Benefit Period"
                                    />
                                </Grid>
                                {
                                    formData['spendingAccountInitialBenefitCalculation'] === 'Same Flat Allocation for all plan members for the Initial Benefit Period' &&
                                        <Grid item xs={6} style={{marginLeft: 20}}>
                                            <FormInput
                                                name="spendingAccountInitialBenefitCalculationAmount"
                                                value={formData['spendingAccountInitialBenefitCalculationAmount'] ?? ''}
                                                type='number'
                                                prefix='$'
                                                onChange={updateFormData}
                                                errors={errors}
                                            />
                                        </Grid>
                                }
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        checked={!!formData['spendingAccountInitialBenefitCalculation'] && formData['spendingAccountInitialBenefitCalculation'] === 'Pro-rate the Annual Allocation'}
                                        onClick={_ => {
                                            if (formData['spendingAccountInitialBenefitCalculation'] === 'Pro-rate the Annual Allocation') {
                                                setFormData({ ...formData, spendingAccountInitialBenefitCalculation: '' })
                                            } else {
                                                setFormData({ ...formData, spendingAccountInitialBenefitCalculation: 'Pro-rate the Annual Allocation' })
                                            }
                                        }}
                                        control={<Checkbox name="spendingAccountInitialBenefitCalculation" color="primary" />}
                                        label="Pro-rate the Annual Allocation (Annual Allocation divided by 365) X (# of days in Initial Benefit Period)"
                                    />
                                </Grid>
                                {
                                    formData['spendingAccountInitialBenefitCalculation'] === 'Pro-rate the Annual Allocation' &&
                                    <Grid item xs={12}>
                                        <p>*Please note that selecting the pro-rate option will pro-rate certificates at Issue as well as new enrollments throughout the benefit period</p>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <p>*Note: All Subsequent Benefit Periods will be 12 months</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <label className="form-section-label-md">5. Maximum Submission Period to submit claims following the end of each Benefit Period: </label>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput
                                        name="maximumSubmissionPeriod"
                                        value={formData['spendingAccountIssued'] === 'Yes' ? '90 days' : ''}
                                        disabled={true}
                                        errors={errors}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <label className="form-section-label-md">6.Takeover Plans only - Will Existing Balances be transferred?: </label>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect
                                        name="spendingAccountExistingBalancesTransferred"
                                        label=''
                                        value={formData['spendingAccountType'] === 'Start Up Plan' ? 'N/A' : formData['spendingAccountExistingBalancesTransferred'] ?? ''}
                                        onChange={updateFormData}
                                        errors={errors}
                                        disabled={formData['spendingAccountType'] === 'Start Up Plan'}
                                        options={
                                            formData['spendingAccountType'] === 'Take Over Plan' ?
                                            [
                                                { label: 'Yes', value: 'Yes' },
                                                { label: 'No', value: 'No' },
                                            ] :
                                            [
                                                { label: 'N/A', value: 'N/A' },
                                            ]
                                        }
                                    />
                                </Grid>
                                { formData['spendingAccountExistingBalancesTransferred'] === 'Yes' &&
                                    <Grid item xs={12}>
                                        <p>*Each Plan member’s balance will be provided in a spreadsheet and the total amount of the balances to be transferred to Equitable Life will be paid once obtained from the prior carrier. </p>
                                    </Grid>
                                }
                                
                                <Grid item xs={12}>
                                    <p><b>7. Special instructions and Additional information</b> (including restricting the HCSA to limited benefits):</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput
                                        name="spendingAccountSpecialInstructions"
                                        value={formData['spendingAccountSpecialInstructions'] ?? ''}
                                        onChange={e => setFormData({ ...formData, spendingAccountSpecialInstructions: e.target.value })}
                                        errors={errors}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">11. BOOKLET IMPRINT</label>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="advisorBookletImprint"
                                value={formData['advisorBookletImprint'] ?? ''}
                                label="The Advisor's name, address, telephone/fax number(s) are to be shown in the booklets exactly as follows:"
                                rows={4}
                                variant='outlined'
                                onChange={updateFormData}
                                errors={errors}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">12. The Equitable Healthconnector™ Services</label>
                        </Grid>
                        <Grid item xs={12}>
                            <p><b>Included Benefits:</b></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p><b>Homewood Health® Employee and Family Assistance Program (EFAP)</b> (mandatory for all active Plan member classes; Retirees, Contract Plan members and Independent Contractors are not eligible)</p>
                            <br/>
                            <FormSelect
                                name="eapInforceLast31Days"
                                label="Is there EAP coverage currently inforce or was it inforce during the last 31 days?"
                                value={formData['eapInforceLast31Days'] ?? ''}
                                onChange={updateFormData}
                                options={yesNoOptions}
                                errors={errors}
                            />
                        </Grid>
                        {
                            formData['eapInforceLast31Days'] === 'Yes' &&
                            <Grid item xs={12} style={{marginLeft: 20}}>
                                <FormInput
                                    name="eapInforceLast31DaysProvider"
                                    label="Please provide the name of the EAP provider"
                                    value={formData['eapInforceLast31DaysProvider'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <b>Additional Optional Selections:</b>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                checked={!!formData['feelingBetterNow'] && formData['feelingBetterNow'] === 'Yes'}
                                onClick={_ => {
                                    if (formData['feelingBetterNow'] === 'Yes') {
                                        setFormData({ ...formData, feelingBetterNow: '' })
                                    } else {
                                        setFormData({ ...formData, feelingBetterNow: 'Yes' })
                                    }
                                }}
                                control={<Checkbox name="feelingBetterNow" color="primary" />}
                                label="FeelingBetterNow® (from Mensante Corporation) (mandatory for All Active Employee Classes; Retirees, Contract Employees and Independent Contractors are not eligible)"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                checked={!!formData['worldCareServices'] && formData['worldCareServices'] === 'Yes'}
                                onClick={_ => {
                                    if (formData['worldCareServices'] === 'Yes') {
                                        setFormData({ ...formData, worldCareServices: '' })
                                    } else {
                                        setFormData({ ...formData, worldCareServices: 'Yes' })
                                    }
                                }}
                                control={<Checkbox name="worldCareServices" color="primary" />}
                                label="WorldCare Services (Remote Second Opinion Service) (from WorldCare International, Inc.) (mandatory for All Active Employee Classes; Retirees, Contractors and Independent Contractors are not eligible)"
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">Advisor Information and Agreement</label>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="groupAccountExecutive"
                                label="Group Account Executive"
                                value={formData['groupAccountExecutive'] ?? ''}
                                onChange={updateFormData}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <label className="form-section-label">A. Primary Advisor Information (As Appears On License)</label>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="primaryBusinessName"
                                label="Business Name"
                                value={formData['primaryBusinessName'] ?? ''}
                                onChange={updateFormData}
                                errors={errors}
                            />
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <FormInput
                                    name="primaryBusinessBranch"
                                    label="Branch #"
                                    value={formData['primaryBusinessBranch'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormInput
                                    name="primaryBusinessAdvisorNumber"
                                    label="Advisor #"
                                    value={formData['primaryBusinessAdvisorNumber'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormInput
                                    name="primaryBusinessCaseSplit"
                                    label="Case Split %"
                                    value={formData['primaryBusinessCaseSplit'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    name="primaryBusinessAdvisorName"
                                    label="Advisor Contact Name"
                                    value={formData['primaryBusinessAdvisorName'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    name="primaryBusinessAdvisorSIN"
                                    label="SIN (if individual advisor)"
                                    value={formData['primaryBusinessAdvisorSIN'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="primaryBusinessStreet"
                                label="Full Street Address"
                                value={formData['primaryBusinessStreet'] ?? ''}
                                onChange={updateFormData}
                                errors={errors}
                            />
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <FormInput
                                    name="primaryBusinessCity"
                                    label="City"
                                    value={formData['primaryBusinessCity'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormSelect
                                    name="primaryBusinessProvince"
                                    label="Province"
                                    value={formData['primaryBusinessProvince'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                    options={provinceOptions}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormInput
                                    name="primaryBusinessPostalCode"
                                    label="Postal Code"
                                    value={formData['primaryBusinessPostalCode'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={6}>
                                <FormMaskedInput
                                    name="primaryBusinessPhone"
                                    label="Phone"
                                    value={formData['primaryBusinessPhone'] ?? ''}
                                    onChange={updateFormData}
                                    errors={errors}
                                    mask="(999) 999-9999"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormMaskedInput
                                    name="primaryBusinessFax"
                                    label="Fax"
                                    value={formData['primaryBusinessFax'] ?? ''}
                                    mask="(999) 999-9999"
                                    onChange={updateFormData}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="primaryBusinessEmail"
                                label="Equinet Email Address for Commission Statement Purposes"
                                value={formData['primaryBusinessEmail'] ?? ''}
                                onChange={updateFormData}
                                errors={errors}
                            />
                        </Grid>
                        {
                            formData['primaryBusinessCaseSplit'] !== '100' &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <label className="form-section-label">B. Secondary Advisor Information (As Appears On License)</label>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput
                                        name="secondaryBusinessName"
                                        label="Business Name"
                                        value={formData['secondaryBusinessName'] ?? ''}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <FormInput
                                            name="secondaryBusinessBranch"
                                            label="Branch #"
                                            value={formData['secondaryBusinessBranch'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormInput
                                            name="secondaryBusinessAdvisorNumber"
                                            label="Advisor #"
                                            value={formData['secondaryBusinessAdvisorNumber'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormInput
                                            name="secondaryBusinessCaseSplit"
                                            label="Case Split %"
                                            value={formData['secondaryBusinessCaseSplit'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormInput
                                            name="secondaryBusinessAdvisorName"
                                            label="Advisor Contact Name"
                                            value={formData['secondaryBusinessAdvisorName'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormInput
                                            name="secondaryBusinessAdvisorSIN"
                                            label="SIN (if individual advisor)"
                                            value={formData['secondaryBusinessAdvisorSIN'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput
                                        name="secondaryBusinessStreet"
                                        label="Full Street Address"
                                        value={formData['secondaryBusinessStreet'] ?? ''}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <FormInput
                                            name="secondaryBusinessCity"
                                            label="City"
                                            value={formData['secondaryBusinessCity'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormSelect
                                            name="secondaryBusinessProvince"
                                            label="Province"
                                            value={formData['secondaryBusinessProvince'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                            options={provinceOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormInput
                                            name="secondaryBusinessPostalCode"
                                            label="Postal Code"
                                            value={formData['secondaryBusinessPostalCode'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2}>
                                    <Grid item xs={6}>
                                        <FormMaskedInput
                                            name="secondaryBusinessPhone"
                                            label="Phone"
                                            value={formData['secondaryBusinessPhone'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                            mask="(999) 999-9999"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormMaskedInput
                                            name="secondaryBusinessFax"
                                            label="Fax"
                                            value={formData['secondaryBusinessFax'] ?? ''}
                                            onChange={updateFormData}
                                            errors={errors}
                                            mask="(999) 999-9999"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput
                                        name="secondaryBusinessEmail"
                                        label="Equinet Email Address for Commission Statement Purposes"
                                        value={formData['secondaryBusinessEmail'] ?? ''}
                                        onChange={updateFormData}
                                        errors={errors}
                                    />
                                </Grid>
                            </React.Fragment>
                        }

                        <Grid item xs={12}>
                            <label className="form-section-label">C. Commission Information</label>
                        </Grid>
                        {
                            !hasCustomCommission ? 
                            <Grid item xs={12}>
                                <FormSelect
                                    name="commissionInformation"
                                    value={formData['commissionInformation'] ?? ''}
                                    disabled={true}
                                    errors={errors}
                                    options={[
                                        { label: 'Equitable Life Standard Commission Scale G&L', value: 'Equitable Life Standard Commission Scale G&L' },
                                        { label: 'Flat Commission', value: 'Flat Commission' },
                                        { label: 'Custom Commission Scale', value: 'Custom Commission Scale' },
                                    ]}
                                />
                            </Grid> : null
                        }

                        {
                            (formData['commissionInformation'] === 'Flat Commission' || hasCustomCommission) &&
                            <React.Fragment>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={6} sm={3} md={2}>
                                        <FormInput
                                            name="commissionOptionOptionalLifePercent"
                                            label="Optional Life"
                                            suffix='%'
                                            value={formData['commissionOptionOptionalLifePercent'] ?? ''}
                                            disabled={true}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={2}>
                                        <FormInput
                                            name="commissionOptionLifePercent"
                                            label="Life"
                                            suffix={formData['commissionOptionLifePercent'] === 'Standard' || formData['commissionOptionLifePercent'] === 'Custom' ? '' : '%'}
                                            value={formData['commissionOptionLifePercent'] ?? ''}
                                            disabled={true}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={2}>
                                        <FormInput
                                            name="commissionOptionDepLifePercent"
                                            label="Dep Life"
                                            suffix={formData['commissionOptionDepLifePercent'] === 'Standard' || formData['commissionOptionDepLifePercent'] === 'Custom' ? '' : '%'}
                                            value={formData['commissionOptionDepLifePercent'] ?? ''}
                                            disabled={true}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={2}>
                                        <FormInput
                                            name="commissionOptionADDPercent"
                                            label="AD&D"
                                            suffix={formData['commissionOptionADDPercent'] === 'Standard' || formData['commissionOptionADDPercent'] === 'Custom' ? '' : '%'}
                                            value={formData['commissionOptionADDPercent'] ?? ''}
                                            disabled={true}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={6} sm={3} md={2}>
                                        <FormInput
                                            name="commissionOptionLTDPercent"
                                            label="LTD"
                                            suffix={formData['commissionOptionLTDPercent'] === 'Standard' || formData['commissionOptionLTDPercent'] === 'Custom' ? '' : '%'}
                                            value={formData['commissionOptionLTDPercent'] ?? ''}
                                            disabled={true}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={2}>
                                        <FormInput
                                            name="commissionOptionEHCPercent"
                                            label="EHC"
                                            suffix={formData['commissionOptionEHCPercent'] === 'Standard' || formData['commissionOptionEHCPercent'] === 'Custom' ? '' : '%'}
                                            value={formData['commissionOptionEHCPercent'] ?? ''}
                                            disabled={true}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={2}>
                                        <FormInput
                                            name="commissionOptionDentalPercent"
                                            label="Dental"
                                            suffix={formData['commissionOptionDentalPercent'] === 'Standard' || formData['commissionOptionDentalPercent'] === 'Custom' ? '' : '%'}
                                            value={formData['commissionOptionDentalPercent'] ?? ''}
                                            disabled={true}
                                            errors={errors}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {
                            formData['spendingAccountIssued'] === 'Yes' &&
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <b>HCSA Commission Scale</b>
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={6} sm={2}>
                                        <FormInput
                                            name="hcsaCommissionScale"
                                            label=""
                                            value={formData['hcsaCommissionScale'] ?? ''}
                                            suffix='%'
                                            onChange={updateFormData}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={9} style={{marginTop: 10}}>
                                        <p> of Paid Claims</p>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }

                        <Grid item xs={12}>
                            <label>*Commissions are as earned</label>
                        </Grid>
                        {
                            (formData['equitableHealthConnectorWorldCareServices'] === 'Yes' || formData['equitableHealthConnectorFeelingBetterNow'] === 'Yes') &&
                            <Grid item xs={12}>
                                <label className="form-section-label-md">Equitable Healthconnector services 10% of Paid Premiums</label>
                            </Grid>
                        }
                        {
                            formData['feelingBetterNow'] === 'Yes' && 
                            <Grid item xs={12}>
                                <FormControlLabel
                                    checked={!!formData['equitableHealthConnectorFeelingBetterNow'] && formData['equitableHealthConnectorFeelingBetterNow'] === 'Yes'}
                                    onClick={_ => {
                                        if (formData['equitableHealthConnectorFeelingBetterNow'] === 'Yes') {
                                            setFormData({ ...formData, equitableHealthConnectorFeelingBetterNow: '' })
                                        } else {
                                            setFormData({ ...formData, equitableHealthConnectorFeelingBetterNow: 'Yes' })
                                        }
                                    }}
                                    control={<Checkbox name="equitableHealthConnectorFeelingBetterNow" color="primary" />}
                                    label="FeelingBetterNow®"
                                />
                            </Grid>
                        }
                        {
                            formData['worldCareServices'] === 'Yes' &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    checked={!!formData['equitableHealthConnectorWorldCareServices'] && formData['equitableHealthConnectorWorldCareServices'] === 'Yes'}
                                    onClick={_ => {
                                        if (formData['equitableHealthConnectorWorldCareServices'] === 'Yes') {
                                            setFormData({ ...formData, equitableHealthConnectorWorldCareServices: '' })
                                        } else {
                                            setFormData({ ...formData, equitableHealthConnectorWorldCareServices: 'Yes' })
                                        }
                                    }}
                                    control={<Checkbox name="equitableHealthConnectorWorldCareServices" color="primary" />}
                                    label="WorldCare Services (Second Opinion)"
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">13. Comments, Special Instructions Not Included Elsewhere</label>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="comments"
                                value={formData['comments'] ?? ''}
                                onChange={e => setFormData({ ...formData, comments: e.target.value })}
                                rows={4}
                                variant='outlined'
                                errors={errors}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">14. Upload Files</label>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={8}>
                                <span>Completed Employee Census</span> 
                                { errors['employeeCensus'] && <span className='file-error'> *This file is required</span> }
                            </Grid>
                            <Grid item xs={4}>
                                <input type="file" onChange={e => handleFilesChange(e, 'employeeCensus')} />
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={8}>
                                <span>Completed Pre-authorized Debit Form <i>(optional)</i></span>
                            </Grid>
                            <Grid item xs={4}>
                            <input type="file" onChange={e => handleFilesChange(e, 'preAuthorizedDebit')} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={8}>
                                <span>Copy of your Current License</span> 
                                { errors['license'] && <span className='file-error'> *This file is required</span> }
                            </Grid>
                            <Grid item xs={4}>
                            <input type="file" onChange={e => handleFilesChange(e, 'license')} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={8}>
                                <span>Void Cheque (for commissions)</span> 
                                { errors['voidCheque'] && <span className='file-error'> *This file is required</span> }
                            </Grid>
                            <Grid item xs={4}>
                                {
                                    formData['voidChequeOnFile'] !== 'Yes' &&
                                        <input type="file" onChange={e => handleFilesChange(e, 'voidCheque')} />
                                }
                            </Grid>
                            <Grid item xs={12} style={{ paddingLeft: 20 }}>
                                <FormControlLabel
                                    checked={!!formData['voidChequeOnFile'] && formData['voidChequeOnFile'] === 'Yes'}
                                    onClick={_ => {
                                        if (formData['voidChequeOnFile'] === 'Yes') {
                                            setFormData({ ...formData, voidChequeOnFile: '' })
                                        } else {
                                            setErrors({ ...errors, voidCheque: '' })
                                            setFormData({ ...formData, voidChequeOnFile: 'Yes' })
                                        }
                                    }}
                                    control={<Checkbox name="voidChequeOnFile" color="primary" />}
                                    label="Banking details are on file with Equitable"
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={8}>
                                <span>Copy of your Current E&O insurance</span> 
                                { errors['eAndOInsurance'] && <span className='file-error'> *This file is required</span> }
                            </Grid>
                            <Grid item xs={4}>
                                {
                                    formData['insuranceOnFile'] !== 'Yes' &&
                                        <input type="file" onChange={e => handleFilesChange(e, 'eAndOInsurance')} />
                                }
                            </Grid>
                            <Grid item xs={12} style={{ paddingLeft: 20 }}>
                                <FormControlLabel
                                    checked={!!formData['insuranceOnFile'] && formData['insuranceOnFile'] === 'Yes'}
                                    onClick={_ => {
                                        if (formData['insuranceOnFile'] === 'Yes') {
                                            setFormData({ ...formData, insuranceOnFile: '' })
                                        } else {
                                            setErrors({ ...errors, eAndOInsurance: '' })
                                            setFormData({ ...formData, insuranceOnFile: 'Yes' })
                                        }
                                    }}
                                    control={<Checkbox name="insuranceOnFile" color="primary" />}
                                    label="Insurance details are on file with Equitable"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="form-group" item spacing={2}>
                        <Grid item xs={12}>
                            <label className="form-section-label">15. Submission</label>
                        </Grid>
                        <Grid item xs={12}>
                            <p>By submitting this application, the policyholder or authorized representative of the policyholder (the “Plan Sponsor”) hereby acknowledges and agrees to <a className="consent-link" href={'/terms-and-conditions'} target='_blank' rel="noreferrer">terms and conditions</a> and the <a className="consent-link" href={'/advisors-agreement'} target='_blank' rel="noreferrer">advisor's agreement</a>.
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={submitButtonDisabled} type="submit" variant="contained" color="primary" style={{ fontWeight: 'bold', fontSize: 18, width: '100%' }}>Submit Application</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </form>
    );
}
