import AdvisorAgreement from "./components/AdvisorAgreement";
import TermsAgreement from "./components/TermsAgreement";
import Footer from "./components/Footer";
import FormSubmitted from "./components/FormSubmitted";
import Header from "./components/Header";
import { Route, Routes } from 'react-router-dom';
import HomePage from "./components/HomePage";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/completed" element={<FormSubmitted />} />
        <Route path="/terms-and-conditions" element={<TermsAgreement />} />
        <Route path="/advisors-agreement" element={<AdvisorAgreement />} />
        <Route path="*" element={<div className="completed-text"><h1>Page Not Found</h1></div>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
