import { InputAdornment, TextField, Typography } from "@mui/material";

type FormInputProps = {
    name: string;
    label?: string;
    value?: string;
    onChange?: any;
    style?: any;
    type?: string;
    errors?: any;
    rows?: number;
    prefix?: string;
    suffix?: string;
    disabled?: boolean;
    variant?: 'standard' | 'filled' | 'outlined';
};

export default function FormInput({ name, label, style, value, onChange, type = 'text', rows, prefix, suffix, disabled = false, variant = 'standard', errors }: FormInputProps) {
    return (
        <div className="form-input" style={style}>
            {!!label && <Typography className="form-input-label" sx={{ fontStyle: 'italic' }}>{label}</Typography>}
            <TextField 
                variant={variant}
                name={name}
                onChange={onChange}
                value={!value || value === 'NaN' ? '' : value}
                type={type}
                error={!!errors[name]}
                helperText={errors[name]}
                multiline={!!rows}
                rows={rows}
                disabled={disabled}
                InputProps={{
                    startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : null,
                    endAdornment: suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : null,
                }}
            />
        </div>
    );
}